import React, { useEffect, useState } from 'react';
import Card from 'components/card'; // Adjust the import path as needed
import { FaStar, FaCertificate, FaUsers, FaMedal } from 'react-icons/fa';
import { fetchTeamRankings } from 'api'; // Make sure you have an API function that fetches the teams

// Define school colors dynamically
const schoolColors = {
  'SIMAD University': 'bg-green-500',
  'Tabsera Academy': 'bg-blue-500',
  'Itqan Academy': 'bg-red-500',
  'iForge Kenya': 'bg-gray-700',
  'iForge Somalia': 'bg-indigo-500',
};

const AllTeams = () => {
  const [teams, setTeams] = useState([]);       // Holds the fetched data
  const [searchValue, setSearchValue] = useState(''); 
  const [loading, setLoading] = useState(true);
  const [error, setError]   = useState(null);

  // Fetch teams from the backend on mount
  useEffect(() => {
    const loadTeams = async () => {
      try {
        setLoading(true);
        const fetchedTeams = await fetchTeamRankings(); 
        // Example structure expected from your API: 
        // [
        //   { id: 1, name: 'Alpha Innovators', schoolName: 'SIMAD University', points: 450, poaps: 1, members: 15, description: '...' },
        //   ...
        // ]
        setTeams(fetchedTeams);
      } catch (err) {
        setError('Error fetching teams from server.');
      } finally {
        setLoading(false);
      }
    };

    loadTeams();
  }, []);

  // Handle loading & error states
  if (loading) {
    return (
      <div className="flex justify-center items-center h-full">
        <div className="text-lg font-semibold text-gray-700 dark:text-gray-300">
          Loading...
        </div>
      </div>
    );
  }
  if (error) {
    return (
      <div className="flex justify-center items-center h-full">
        <div className="text-lg font-semibold text-red-500">
          {error}
        </div>
      </div>
    );
  }
  if (!teams || teams.length === 0) {
    return (
      <div className="flex flex-col items-center p-6 bg-white dark:bg-navy-800 rounded-lg shadow-lg dark:shadow-none">
        <FaMedal className="text-6xl text-orange-500 mb-4" />
        <h2 className="mb-2 text-2xl font-bold text-center text-gray-800 dark:text-white">
          No Teams Ranked Yet
        </h2>
        <p className="text-center text-gray-600 dark:text-gray-300 mb-6">
          Be the first to create a team and start earning points to appear on the leaderboard!
        </p>
      </div>
    );
  }

  // Sort teams by points in descending order
  const rankedTeams = [...teams].sort((a, b) => b.points - a.points);

  // Filter by team name
  const filteredTeams = rankedTeams.filter(team =>
    team.name.toLowerCase().includes(searchValue.toLowerCase())
  );

  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900">
      {/* Header Section */}
      <header className="relative py-6 text-gray-800 dark:text-white">
        <div className="container mx-auto px-4">
          <h1 className="text-4xl font-bold">Leaderboard</h1>
          <p className="mt-2 text-lg text-gray-600 dark:text-gray-300">
            Explore teams, view achievements, and track progress.
          </p>
        </div>
        {/* Divider */}
        <div className="mt-4 border-b border-gray-300 dark:border-gray-700"></div>
      </header>

      {/* Search Bar */}
      <div className="container mx-auto px-4 py-4">
        <input
          type="text"
          placeholder="Search teams..."
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          className="w-full max-w-md px-4 py-2 border border-gray-300 dark:border-gray-700 rounded-lg 
                     focus:outline-none focus:ring-2 focus:ring-blue-500 
                     bg-white dark:bg-navy-800 text-gray-800 dark:text-white"
        />
      </div>

      {/* Teams Grid Section */}
      <section className="container mx-auto px-4 py-6">
        {filteredTeams.length > 0 ? (
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
            {filteredTeams.map((team, index) => (
              <Card
                key={team.id}
                extra="p-6 transform transition-transform duration-300 hover:scale-105"
              >
                <div className="flex flex-col h-full">
                  {/* Title and School Badge */}
                  <div className="flex justify-between items-center">
                    <h2 className="text-xl font-bold text-gray-800 dark:text-white">
                      {index + 1}. {team.name}
                    </h2>
                    {/* Use 'schoolName' (from the DB) instead of 'school' if your API returns that */}
                    <span
                      className={
                        `px-3 py-1 text-white rounded-full text-xs font-semibold ` +
                        (schoolColors[team.schoolName] || 'bg-gray-400')
                      }
                    >
                      {team.schoolName}
                    </span>
                  </div>
                  
                  {/* Description */}
                  <p className="mt-3 text-gray-600 dark:text-gray-300 flex-grow">
                  {team.description || "No description available for this team."}
                  </p>

                  {/* Stats */}
                  <div className="mt-4 flex justify-between">
                    {/* Points */}
                    <div className="flex flex-col items-center">
                      <FaStar className="text-yellow-500" />
                      <span className="mt-1 text-lg font-bold text-gray-800 dark:text-white">
                        {team.points}
                      </span>
                      <span className="text-sm text-gray-500 dark:text-gray-400">Points</span>
                    </div>

                    {/* POAPs */}
                    <div className="flex flex-col items-center">
                      <FaCertificate className="text-purple-500" />
                      <span className="mt-1 text-lg font-bold text-gray-800 dark:text-white">
                        {team.poaps} 
                      </span>
                      <span className="text-sm text-gray-500 dark:text-gray-400">POAPs</span>
                    </div>

                    {/* Members */}
                    <div className="flex flex-col items-center">
                      <FaUsers className="text-blue-500" />
                      <span className="mt-1 text-lg font-bold text-gray-800 dark:text-white">
                        {team.memberCount || team.members}
                      </span>
                      <span className="text-sm text-gray-500 dark:text-gray-400">Members</span>
                    </div>
                  </div>
                </div>
              </Card>
            ))}
          </div>
        ) : (
          <div className="text-center text-gray-600 dark:text-gray-300">
            No teams found.
          </div>
        )}
      </section>
    </div>
  );
};

export default AllTeams;

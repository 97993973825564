import React from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import Loader from "components/loaders/Loader";
import Banner from "./components/Banner";
import General from "./components/General";
import Notification from "./components/Notification";
import Project from "./components/Project";
import Storage from "./components/Storage";
import Upload from "./components/Upload";
import { useNavigate } from "react-router-dom";
import { MdSettings } from "react-icons/md";
import MentorInfo from "./components/MentorInfo";
import MentorNotifications from "./components/MentorNotifications";
import MentorsBadge from "./components/MentorsBadge";
import {
  getUserProfile,
  fetchCurrentDescription,
  fetchPoapDescriptions,
  fetchSpecificStepStatus,
  uploadProfilePic,
} from "api";
import MentorBanner from "./components/MentorBanner";

// Function to fetch and build the entire profile + POAP data at once,
// making step-status calls in parallel to avoid the nested loop slowdown.
const fetchProfileData = async () => {
  const profile = await getUserProfile();
  const user = profile.user;
  const role = user.role;

  // Default POAP data
  let tasksCompleted = 0;
  let poapLevel = "Explorer";
  let poapCount = 0;

  // If the user has a team, fetch all POAP details
  if (user.team) {
    const teamId = user.team.id;
    // Fetch POAP descriptions + current description in parallel
    const [poapDescriptions, currentDescription] = await Promise.all([
      fetchPoapDescriptions(),
      fetchCurrentDescription(teamId),
    ]);

    // Calculate completed POAPs and tasks
    const completedPoaps = poapDescriptions.filter(
      (poap) => poap.id < currentDescription.descriptionId
    );
    poapCount = completedPoaps.length;
    poapLevel = poapCount > 0 ? completedPoaps[poapCount - 1].title : "Explorer";

    // Collect all step-status requests in one array
    const stepStatusPromises = [];
    for (const poap of poapDescriptions) {
      for (const category of poap.categories) {
        for (const step of category.steps) {
          stepStatusPromises.push(
            fetchSpecificStepStatus(teamId, poap.id, step.id)
          );
        }
      }
    }

    // Resolve them in parallel and count "completed" statuses
    const stepResults = await Promise.all(stepStatusPromises);
    tasksCompleted = stepResults.reduce(
      (count, status) => (status.status === "completed" ? count + 1 : count),
      0
    );
  }

  return {
    user,
    role,
    poapData: { tasksCompleted, poapLevel, poapCount },
  };
};

const ProfileOverview = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient(); 

  // React Query fetch for all profile data using the object syntax
  const { data, isLoading, isError, error } = useQuery({
    queryKey: ["profileData"],
    queryFn: fetchProfileData,
    staleTime: 300000, // 5 minutes, adjust as needed
    cacheTime: 600000, // 10 minutes, adjust as needed
  });

  // Handle uploading a new profile picture
  const handleImageChange = async (file) => {
    if (file) {
      const formData = new FormData();
      formData.append("profilePic", file);
      try {
        await uploadProfilePic(formData);
        // Optionally, you could refetch the profile data here or update the cache.
        // For now, we'll assume the component updates elsewhere.
        queryClient.invalidateQueries(["profileData"]);
      } catch (error) {
        console.error("Error uploading profile picture:", error);
      }
    }
  };

  const handleSettingsClick = () => {
    navigate("/admin/main/account/settings"); // Replace with your actual settings route
  };

  if (isLoading) return <Loader />;
  if (isError) {
    console.error("Error fetching profile data:", error);
    return <div>Error loading profile data.</div>;
  }

  // Destructure fetched data
  const { user, role, poapData } = data;

  return (
    <div className="flex w-full flex-col gap-5 lg:gap-5">
      <div className="w-full mt-3 flex h-fit flex-col gap-5 lg:grid lg:grid-cols-12">
        <div className="col-span-4 lg:!mb-0">
          {role === "mentor" ? (
            <MentorBanner
              userProfile={user}
              onChangeProfilePicture={handleImageChange}
            />
          ) : (
            <Banner
              userProfile={user}
              poapData={poapData}
              onChangeProfilePicture={handleImageChange}
            />
          )}
        </div>

        <div className="col-span-3 lg:!mb-0">
          {role === "mentor" ? <MentorsBadge /> : <Storage />}
        </div>
        <div className="z-0 col-span-5 lg:!mb-0">
          {role === "mentor" ? <MentorInfo userProfile={user} /> : <Upload />}
        </div>
      </div>

      <div className="mb-4 grid h-full grid-cols-1 gap-5 lg:!grid-cols-12">
        <div className="col-span-5 lg:col-span-6 lg:mb-0 3xl:col-span-4">
          <Project />
        </div>
        <div className="col-span-5 lg:col-span-6 lg:mb-0 3xl:col-span-5">
          <General />
        </div>

        <div className="col-span-5 lg:col-span-12 lg:mb-0 3xl:!col-span-3">
          {role === "mentor" ? (
            <MentorNotifications userProfile={user} />
          ) : (
            <Notification />
          )}
        </div>
      </div>

      <div className="flex justify-end mt-5">
        <button
          onClick={handleSettingsClick}
          className="flex items-center justify-center rounded-xl bg-brand-500 px-4 py-2 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:bg-brand-200"
        >
          <MdSettings className="mr-2 text-xl" /> Change Settings
        </button>
      </div>
    </div>
  );
};

export default ProfileOverview;

import React, { useMemo, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { MdPersonAdd, MdAddBox, MdGroupAdd, MdSettings, MdEdit } from "react-icons/md";
import { useGlobalFilter, usePagination, useSortBy, useTable } from "react-table";
import Card from "components/card";
import SearchModal from "./SearchModal";
import CreateTeam from "./CreateTeam";
import ErrorAlerts from "components/alerts/ErrorAlerts";

import Modal from "components/modal/Modal"; // <-- Import your existing Modal component
import { motion, AnimatePresence } from "framer-motion";
import { io } from "socket.io-client";
import { FiEdit, FiUserMinus, FiCheck, FiX } from "react-icons/fi";

// ===== IMPORTS FOR API CALLS =====
import { updateTeam, updateTeamMember, removeTeamMember } from "api";

import AnimatedText from "./AnimatedText";

const socket = io(process.env.REACT_APP_API_BASE_URL, { transports: ["websocket"] });

const maskEmail = (email) => {
  if (!email) return "";
  const visible = email.slice(0, 3);
  const masked = "*".repeat(email.length - 3);
  return visible + masked;
};

const UserReportsTable = (props) => {
  const { columnsData, tableData, isModalOpen, toggleModal, selectedTeam, userProfile } = props;

  // -----------------------------
  // LOCAL STATES
  // -----------------------------

  const [isCreateTeamModalOpen, setCreateTeamModalOpen] = useState(false);
  const [isTeamSettingsModalOpen, setTeamSettingsModalOpen] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const [updatedTableData, setUpdatedTableData] = useState(tableData);

  // --- Member removal confirmation
  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false);
  const [confirmRemoveUserId, setConfirmRemoveUserId] = useState(null);

  // --- Expanded row action menus
  const [expandedActions, setExpandedActions] = useState({});

  // --- Inline editing for roles
  const [editingUserId, setEditingUserId] = useState(null);
  const [expandedEditor, setExpandedEditor] = useState({});
  const [selectedRole, setSelectedRole] = useState("");

 

  // --- Editing the team name
  const [isEditingTeamName, setIsEditingTeamName] = useState(false);
  const [teamName, setTeamName] = useState("");

  // === NEW: Confirmation modal for team name changes ===
  const [isConfirmTeamNameModalOpen, setIsConfirmTeamNameModalOpen] = useState(false);

  // Are we allowed to see buttons?
  const canAccessButtons = userProfile?.user?.teamRole === "Team Leader" || !selectedTeam;

  // -----------------------------
  // EFFECTS & INITIALIZATION
  // -----------------------------

  // Sync local teamName with the selectedTeam prop
  useEffect(() => {
    if (selectedTeam) {
      setTeamName(selectedTeam.name || "");
    } else {
      setTeamName("");
    }
  }, [selectedTeam]);

  // If tableData changes, update local data
  useEffect(() => {
    setUpdatedTableData(tableData);
  }, [tableData]);

  // Socket for real-time status updates
  useEffect(() => {
    socket.on("userStatusUpdate", ({ userId, status }) => {
      setUpdatedTableData((prevData) =>
        prevData.map((user) => (user.id === userId ? { ...user, status } : user))
      );
    });
    return () => {
      socket.off("userStatusUpdate");
    };
  }, []);

  // -----------------------------
  // REACT-TABLE SETUP
  // -----------------------------

  const columns = useMemo(() => {
    let filtered = columnsData.filter((col) => col.Header !== "ACTIONS");

    if (canAccessButtons) {
      filtered.push({
        Header: "ACTIONS",
        accessor: "actions",
        Cell: ({ row }) => {
          const rowIndex = row.index;
          return (
            <div className="relative">
              <button
                onClick={() => toggleRowActions(rowIndex)}
                className="flex items-center justify-center text-gray-500 hover:text-gray-700 focus:outline-none transition-transform duration-200 ease-in-out"
              >
                <MdSettings size={20} />
              </button>
              <AnimatePresence>
                {expandedActions[rowIndex] && (
                  <motion.div
                    initial={{ opacity: 0, y: -10 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -10 }}
                    transition={{ duration: 0.2, ease: "easeInOut" }}
                    className="absolute right-0 mt-2 bg-white dark:bg-gray-800 shadow-lg rounded-md divide-y divide-gray-200 dark:divide-gray-700"
                    style={{ zIndex: 10 }}
                  >
                    <button
                      onClick={() => handleEditRole(rowIndex, row.original.id)}
                      className="w-full px-4 py-3 text-sm text-[#FF6600] hover:bg-orange-50 dark:hover:bg-gray-700 text-left transition-colors"
                    >
                      <div className="flex items-center space-x-2">
                        <FiEdit size={18} />
                        <span>Role</span>
                      </div>
                    </button>
                    <button
                      onClick={() => handlePromptRemoveMember(row.original.id)}
                      className="w-full px-4 py-3 text-sm text-red-600 hover:bg-red-50 dark:hover:bg-gray-700 text-left transition-colors"
                    >
                      <div className="flex items-center space-x-2">
                        <FiUserMinus size={18} />
                        <span>Remove</span>
                      </div>
                    </button>
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          );
        },
      });
    }

    return filtered;
  }, [columnsData, expandedActions, canAccessButtons]);

  const data = useMemo(() => updatedTableData, [updatedTableData]);

  const tableInstance = useTable(
    { columns, data },
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    initialState,
    state,
  } = tableInstance;

  // Set default page size
  initialState.pageSize = 6;

  const { pageSize } = state; // not used, but included from your code

  // Navigation
  const navigate = useNavigate();

  // -----------------------------
  // HANDLERS
  // -----------------------------

  // 1) New Project
  const handleNewProject = () => {
    navigate("/admin/new-project");
  };

  // 2) Create Team
  const handleCreateTeam = () => {
    if (userProfile?.user?.teamRole === "Team Leader" && selectedTeam) {
      setErrorMessages(["You are already leading a team and cannot create another."]);
    } else {
      navigate("/admin/create-team");
    }
  };

  const handleCloseCreateTeam = () => {
    setCreateTeamModalOpen(false);
  };

  const handleCloseErrorAlert = () => {
    setErrorMessages([]);
  };

  // 3) Team Settings
  const handleTeamSettings = () => {
    setTeamSettingsModalOpen(true);
  };

  // 4) Removing a member
  const handleDeleteMember = async (userId) => {
    try {
      if (!selectedTeam) {
        console.log("No team selected. Cannot remove member.");
        return;
      }
      if (!userId) {
        console.error("Invalid user ID. Cannot remove member.");
        setErrorMessages(["Invalid user ID. Cannot remove member."]);
        return;
      }
      await removeTeamMember(selectedTeam.id, userId);
      setUpdatedTableData((prev) => prev.filter((u) => u.id !== userId));
    } catch (error) {
      console.error("Error removing member:", error);
      setErrorMessages(["Failed to remove team member. Please try again."]);
    }
  };

  // 5) Prompt remove member
  const handlePromptRemoveMember = (userId) => {
    setConfirmRemoveUserId(userId);
    setIsRemoveModalOpen(true);
  };

  // 6) Toggle row actions
  const toggleRowActions = (rowIndex) => {
    setExpandedActions((prev) => ({
      ...prev,
      [rowIndex]: !prev[rowIndex],
    }));
  };

  // 7) Edit role inline
  const handleEditRole = (rowIndex, userId) => {
    const user = updatedTableData.find((u) => u.id === userId);
    if (user) {
      setEditingUserId(userId);
      setSelectedRole(user.role);
      setExpandedEditor((prev) => ({ ...prev, [rowIndex]: true }));
      setExpandedActions((prev) => ({ ...prev, [rowIndex]: false }));
    }
  };

  // 8) Confirm role change
  const handleConfirmRoleChange = async (userId) => {
    try {
      if (!selectedTeam) {
        console.log("No team selected. Cannot change role.");
        return;
      }
      await updateTeamMember(selectedTeam.id, userId, selectedRole);
      setUpdatedTableData((prevData) =>
        prevData.map((user) =>
          user.id === userId ? { ...user, role: selectedRole } : user
        )
      );
      setEditingUserId(null);
    } catch (error) {
      console.error("Error updating team role:", error);
    }
  };

  // -----------------------------
  // ROLE + DUPLICATE ROLE LOGIC
  // -----------------------------

  // We have up to 5 members
  const totalTeamMembers = updatedTableData.length + 1;
  const canAddMember = selectedTeam && totalTeamMembers < 5;

  // All possible roles
  const roles = [
    "Team Leader",
    "Design Specialist",
    "Technical Specialist",
    "Research and Development Specialist",
    "Documentation and Presentation Specialist",
  ];

  // Already assigned roles (except the user being edited)
  const assignedRoles = editingUserId
    ? updatedTableData
        .filter((user) => user.id !== editingUserId)
        .map((user) => user.role)
    : [];

  // userOldRole - if user had a role previously
  const userOldRole = editingUserId
    ? updatedTableData.find((u) => u.id === editingUserId)?.role || ""
    : "";

  // If user picks a role that’s assigned, we open an override modal
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [pendingRole, setPendingRole] = useState("");

  const handleRoleSelection = (newRole) => {
    if (assignedRoles.includes(newRole) && newRole !== userOldRole) {
      setPendingRole(newRole);
      setShowWarningModal(true);
    } else {
      setSelectedRole(newRole);
    }
  };

  const confirmOverride = () => {
    // Let them override
    setSelectedRole(pendingRole);
    setShowWarningModal(false);
  };

  const cancelOverride = () => {
    setShowWarningModal(false);
    setPendingRole("");
  };

  // -----------------------------
  // TEAM NAME EDITING + CONFIRMATION
  // -----------------------------

  const startEditingTeamName = () => {
    setIsEditingTeamName(true);
  };

  const handleCancelTeamNameChange = () => {
    // revert local input
    setTeamName(selectedTeam?.name || "");
    setIsEditingTeamName(false);
  };

  // Instead of calling updateTeam directly, we show a confirm modal
  const handleOpenTeamNameConfirm = () => {
    setIsConfirmTeamNameModalOpen(true);
  };

  const handleCloseTeamNameConfirm = () => {
    setIsConfirmTeamNameModalOpen(false);
  };

  const handleConfirmTeamNameChange = async () => {
    try {
      if (!selectedTeam || userProfile?.user?.teamRole !== "Team Leader") {
        return;
      }
      await updateTeam(selectedTeam.id, { name: teamName });
      setIsEditingTeamName(false);
      setIsConfirmTeamNameModalOpen(false);
    } catch (error) {
      console.error("Error updating team name:", error);
      setErrorMessages(["Failed to update team name. Please try again."]);
      setIsConfirmTeamNameModalOpen(false);
    }
  };
  

  // -----------------------------
  // RENDER
  // -----------------------------

  return (
    <>
      {/* ERRORS */}
      {errorMessages.length > 0 && (
        <ErrorAlerts errors={errorMessages} onClose={handleCloseErrorAlert} />
      )}

      {/* ========== Role Conflict Warning Modal ========== */}
      {showWarningModal && (
        <div className="fixed inset-0 z-[2000] flex items-center justify-center bg-black bg-opacity-50 p-4 ">
          <div className="w-full max-w-md rounded-lg bg-white dark:bg-gray-800 shadow-lg p-6">
            <h2 className="text-lg font-semibold text-red-600 mb-4">Role Already Assigned</h2>
            <p className="text-sm text-gray-600 dark:text-gray-300 mb-6">
              The role <strong>{pendingRole}</strong> is already assigned to another member.
              Do you want to override it anyway?
            </p>
            <div className="flex justify-end space-x-4">
              <button
                onClick={cancelOverride}
                className="px-4 py-2 rounded-md bg-gray-200 dark:bg-gray-700 text-gray-700 dark:text-white"
              >
                Cancel
              </button>
              <button
                onClick={confirmOverride}
                className="px-4 py-2 rounded-md bg-red-600 text-white hover:bg-red-700"
              >
                Override
              </button>
            </div>
          </div>
        </div>
      )}

      {/* ========== Team Name Change Confirmation Modal ========== */}
      {isConfirmTeamNameModalOpen && (
        <Modal onClose={handleCloseTeamNameConfirm}>
          <h3 className="text-lg font-semibold text-gray-900 dark:text-gray-100 mb-4">
            Confirm Team Name Change
          </h3>
          <p className="text-gray-700 dark:text-gray-300">
            Are you sure you want to change the team name from{" "}
            <strong>{selectedTeam?.name}</strong> to{" "}
            <strong>{teamName}</strong>?
          </p>
          <div className="mt-6 flex justify-end space-x-4">
            <button
              onClick={handleCloseTeamNameConfirm}
              className="px-4 py-2 bg-gray-300 text-gray-900 rounded-lg hover:bg-gray-400 dark:bg-gray-700 dark:text-gray-200 dark:hover:bg-gray-600"
            >
              Cancel
            </button>
            <button
              onClick={handleConfirmTeamNameChange}
              className="px-4 py-2 bg-brand-900 text-white rounded-lg hover:bg-orange-600"
            >
              Confirm
            </button>
          </div>
        </Modal>
      )}

      {/* ========== Remove Member Confirmation Modal ========== */}
      {isRemoveModalOpen && (
        <Modal onClose={() => setIsRemoveModalOpen(false)}>
          <h3 className="text-lg font-semibold text-gray-900 dark:text-gray-100 mb-4">
            Confirm Removal
          </h3>
          <p className="text-gray-700 dark:text-gray-300">
            Are you sure you want to remove this team member? This action cannot be undone.
          </p>
          <div className="mt-6 flex justify-end space-x-4">
            <button
              onClick={() => setIsRemoveModalOpen(false)}
              className="px-4 py-2 bg-gray-300 text-gray-900 rounded-lg hover:bg-gray-400 dark:bg-gray-700 dark:text-gray-200 dark:hover:bg-gray-600"
            >
              Cancel
            </button>
            <button
              onClick={async () => {
                await handleDeleteMember(confirmRemoveUserId);
                setIsRemoveModalOpen(false);
                setConfirmRemoveUserId(null);
              }}
              className="px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700"
            >
              Remove
            </button>
          </div>
        </Modal>
      )}

      {/* MAIN CARD */}
      <Card extra="w-full p-6 overflow-x-auto lg:overflow-x-hidden min-h-[80vh]">
        <div
          className={`mb-4 flex flex-col lg:flex-row justify-between items-center space-y-4 lg:space-y-0 mt-4 ${
            !selectedTeam ? "pb-80" : ""
          }`}
        >
          {/* Left: Team Name (view or edit) */}
          {selectedTeam ? (
            <div className="flex items-center space-x-2">
              {isEditingTeamName ? (
                <>
                  <input
                    type="text"
                    className="rounded-lg border border-gray-300 px-2 py-1 text-navy-700 dark:text-white dark:bg-gray-800 focus:outline-none"
                    value={teamName}
                    onChange={(e) => setTeamName(e.target.value)}
                  />
                  {/* Instead of calling handleConfirmTeamNameChange directly, open the confirm modal */}
                  <button
                    onClick={() => setIsConfirmTeamNameModalOpen(true)}
                    className="flex items-center rounded-full bg-green-600 hover:bg-green-700 text-white px-3 py-2 transition duration-200 shadow-md"
                  >
                    <FiCheck size={18} />
                  </button>
                  <button
                    onClick={handleCancelTeamNameChange}
                    className="flex items-center rounded-full bg-red-600 hover:bg-red-700 text-white px-3 py-2 transition duration-200 shadow-md"
                  >
                    <FiX size={18} />
                  </button>
                </>
              ) : (
                <>
                  <h2 className="text-xl font-bold text-navy-700 dark:text-white">
                    {teamName}
                  </h2>
                  {userProfile?.user?.teamRole === "Team Leader" && (
                    <button
                      onClick={startEditingTeamName}
                      className="text-gray-500 hover:text-gray-700 dark:hover:text-white"
                    >
                      <FiEdit size={18} />
                    </button>
                  )}
                </>
              )}
            </div>
          ) : (
            <div className="text-gray-400 font-medium italic">No team selected</div>
          )}

          {/* Right: Buttons */}
          {canAccessButtons && (
            <div className="flex flex-col lg:flex-row items-center space-y-4 lg:space-y-0 lg:space-x-4">
              {!selectedTeam && (
                <button
                  onClick={handleCreateTeam}
                  className="flex items-center justify-center space-x-2 rounded-xl bg-orange-500 px-5 py-3 text-base font-medium text-white transition duration-200 hover:bg-orange-600 active:bg-orange-700 dark:bg-orange-400 dark:text-white dark:hover:bg-orange-300 dark:active:bg-orange-200 w-full lg:w-auto"
                >
                  <MdGroupAdd />
                  <span>Create Team</span>
                </button>
              )}
              <div className="relative group w-full lg:w-auto">
                <button
                  onClick={canAddMember ? toggleModal : null}
                  className={`flex items-center justify-center space-x-2 rounded-xl border-2 px-5 py-3 text-base font-medium transition duration-200 w-full lg:w-auto ${
                    canAddMember
                      ? "border-orange-500 text-orange-500 hover:bg-orange-600/5 active:bg-orange-700/5 dark:border-orange-400 dark:bg-orange-400/10 dark:text-white dark:hover:bg-orange-300/10 dark:active:bg-orange-200/10"
                      : "border-gray-300 text-gray-400 bg-gray-100 cursor-not-allowed"
                  }`}
                  disabled={!canAddMember}
                >
                  <MdPersonAdd />
                  <span>Add Member</span>
                </button>
                {!canAddMember && (
                  <div className="absolute top-full left-1/2 transform -translate-x-1/2 mt-2 w-60 px-4 py-2 bg-gray-800 text-white text-sm rounded-md opacity-0 group-hover:opacity-100 transition-opacity duration-200 text-center">
                    {selectedTeam ? (
                      <>
                        <span className="block">Your team already has 5 members.</span>
                        <span className="block mt-1 text-orange-400">
                          Cannot add more members.
                        </span>
                      </>
                    ) : (
                      <>
                        <span className="block">You need to create a team to add members.</span>
                        <span className="block mt-1 text-orange-400">Click "Create Team"</span>
                      </>
                    )}
                    <div className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-full h-2 w-2 bg-gray-800 rotate-45"></div>
                  </div>
                )}
              </div>
              <button
                onClick={handleNewProject}
                className="flex items-center justify-center space-x-2 rounded-xl bg-gradient-to-br from-[#FFC837] to-[#FF6108] px-5 py-3 text-base font-medium text-white transition duration-200 hover:shadow-lg hover:shadow-[#FFC837]/50 w-full lg:w-auto"
              >
                <MdAddBox />
                <span>New Project</span>
              </button>
            </div>
          )}
        </div>

        {/* If there's a selected team, show table; otherwise show placeholder */}
        {selectedTeam ? (
          <table {...getTableProps()} className="w-full">
            <thead className="w-full">
              {headerGroups.map((headerGroup, hIndex) => (
                <tr
                  key={hIndex}
                  {...headerGroup.getHeaderGroupProps()}
                  className="items-center border-b border-gray-200 dark:!border-white/10"
                >
                  {headerGroup.headers.map((column, cIndex) => (
                    <th
                      key={cIndex}
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      className="px-[19px] pt-[32px] pb-[12px] text-left text-xs tracking-wide text-gray-600"
                    >
                      <div className="text-xs font-bold">{column.render("Header")}</div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>

            <tbody {...getTableBodyProps()} className="w-full">
              {page.map((row, rowIndex) => {
                prepareRow(row);
                return (
                  <tr
                    key={rowIndex}
                    {...row.getRowProps()}
                    className="border-b border-gray-200 dark:!border-white/10"
                  >
                    {row.cells.map((cell, cellIndex) => {
                      let data;

                      // FULL NAME
                      if (cell.column.Header === "FULL NAME") {
                        const imageUrl = cell.row.original.imageUrl;
                        const serverUrl = process.env.REACT_APP_API_BASE_URL;
                        data = (
                          <div className="flex w-full items-center gap-[14px]">
                            <div className="flex h-[55px] w-[55px] items-center justify-center rounded-full bg-blue-300">
                            <img
                              className="h-full w-full rounded-full object-cover"
                              src={
                                imageUrl
                                  ? (imageUrl.startsWith("http")
                                      ? imageUrl
                                      : `${serverUrl.replace(/\/$/, "")}/${imageUrl.replace(/^\/uploads/, "uploads/images")}`)
                                  : "https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y&s=100"
                              }
                              alt={cell.value}
                              onError={(e) => {
                                e.target.onerror = null; // Prevent infinite loop
                                e.target.src =
                                  "https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y&s=100";
                              }}
                            />
                            </div>
                            <p className="font-medium text-navy-700 dark:text-white">
                              {cell.value}
                            </p>
                          </div>
                        );
                      }

                      // STATUS
                      else if (cell.column.Header === "STATUS") {
                        const status = cell.row.original.status;
                        data = (
                          <div
                            className={`inline-flex items-center px-3 py-1 text-sm font-semibold rounded-full border ${
                              status === "Online"
                                ? "border-green-600 text-green-600"
                                : "border-red-500 text-red-500"
                            }`}
                            style={{ minWidth: "100px", justifyContent: "center" }}
                          >
                            {status || "Offline"}
                          </div>
                        );
                      }

                      // ACTIONS
                      else if (cell.column.Header === "ACTIONS") {
                        data =
                          userProfile?.user?.teamRole === "Team Leader" && selectedTeam ? (
                            <div className="relative">
                              <button
                                onClick={() => toggleRowActions(rowIndex)}
                                className="flex items-center justify-center text-gray-500 hover:text-gray-700 focus:outline-none transition-transform duration-200 ease-in-out"
                              >
                                <MdSettings size={20} />
                              </button>
                              <AnimatePresence>
                                {expandedActions[rowIndex] && (
                                  <motion.div
                                    initial={{ opacity: 0, y: -10 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    exit={{ opacity: 0, y: -10 }}
                                    transition={{ duration: 0.2, ease: "easeInOut" }}
                                    className="absolute right-0 mt-2 bg-white dark:bg-gray-800 shadow-lg rounded-md divide-y divide-gray-200 dark:divide-gray-700"
                                    style={{ zIndex: 10 }}
                                  >
                                    <button
                                      onClick={() =>
                                        handleEditRole(rowIndex, cell.row.original.id)
                                      }
                                      className="w-full px-4 py-3 text-sm text-[#FF6600] hover:bg-orange-50 dark:hover:bg-gray-700 text-left transition-colors"
                                    >
                                      <div className="flex items-center space-x-2">
                                        <FiEdit size={18} />
                                        <span>Role</span>
                                      </div>
                                    </button>
                                    <button
                                      onClick={() =>
                                        handlePromptRemoveMember(cell.row.original.id)
                                      }
                                      className="w-full px-4 py-3 text-sm text-red-600 hover:bg-red-50 dark:hover:bg-gray-700 text-left transition-colors"
                                    >
                                      <div className="flex items-center space-x-2">
                                        <FiUserMinus size={18} />
                                        <span>Remove</span>
                                      </div>
                                    </button>
                                  </motion.div>
                                )}
                              </AnimatePresence>
                            </div>
                          )
                          : null;
                      }

                      // ROLE (inline editing with potential override)
                      else if (cell.column.Header === "ROLE") {
                        if (editingUserId === cell.row.original.id && expandedEditor[rowIndex]) {
                          data = (
                            <AnimatePresence>
                              <motion.div
                                initial={{ opacity: 0, y: -10 }}
                                animate={{ opacity: 1, y: 0 }}
                                exit={{ opacity: 0, y: -10 }}
                                transition={{ duration: 0.2 }}
                                className="flex items-center space-x-2"
                              >
                                <select
                                  value={selectedRole}
                                  onChange={(e) => {
                                    const newRole = e.target.value;
                                    if (
                                      assignedRoles.includes(newRole) &&
                                      newRole !== userOldRole
                                    ) {
                                      setPendingRole(newRole);
                                      setShowWarningModal(true);
                                    } else {
                                      setSelectedRole(newRole);
                                    }
                                  }}
                                  className="p-2 rounded-lg border border-gray-300 dark:border-gray-700 bg-white dark:bg-gray-800 text-navy-700 dark:text-white focus:outline-none"
                                >
                                  {roles.map((roleOption) => {
                                    const isTaken =
                                      assignedRoles.includes(roleOption) &&
                                      roleOption !== userOldRole;
                                    return (
                                      <option key={roleOption} value={roleOption}>
                                        {roleOption}
                                        {isTaken ? " (Assigned)" : ""}
                                      </option>
                                    );
                                  })}
                                </select>

                                {/* Confirm button */}
                                <button
                                  onClick={() => {
                                    handleConfirmRoleChange(cell.row.original.id);
                                    setExpandedEditor((prev) => ({
                                      ...prev,
                                      [rowIndex]: false,
                                    }));
                                  }}
                                  className="flex items-center rounded-full bg-green-600 hover:bg-green-700 text-white px-4 py-2 transition duration-200 shadow-md"
                                >
                                  <FiCheck size={18} />
                                </button>

                                {/* Cancel button */}
                                <button
                                  onClick={() => {
                                    setExpandedEditor((prev) => ({
                                      ...prev,
                                      [rowIndex]: false,
                                    }));
                                    setEditingUserId(null);
                                  }}
                                  className="flex items-center rounded-full bg-red-600 hover:bg-red-700 text-white px-4 py-2 transition duration-200 shadow-md"
                                >
                                  <FiX size={18} />
                                </button>
                              </motion.div>
                            </AnimatePresence>
                          );
                        } else {
                          // Default: just show the role
                          data = (
                            <div className="w-full text-base font-medium text-navy-700 dark:text-white">
                              {cell.value}
                            </div>
                          );
                        }
                      }  
                      // EMAIL column: mask the email value
                      else if (cell.column.Header === "EMAIL") {
                        const email = cell.value;
                        const maskedEmail = maskEmail(email);
                        data = (
                          <div className="w-full flex items-center justify-start text-base font-medium text-navy-700 dark:text-white">
                            <span className="inline-flex items-center">{maskedEmail}</span>
                          </div>
                        );
                      }                    
                      else {
                        // Default cell
                        data = (
                          <div className="w-full text-base font-medium text-navy-700 dark:text-white">
                            {cell.value}
                          </div>
                        );
                      }

                      return (
                        <td
                          key={cellIndex}
                          {...cell.getCellProps()}
                          className="mt-[20px] px-4 py-[16px]"
                        >
                          {data}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : (
          // Show placeholder if no team selected
          <div className="flex justify-center items-center min-h-[60vh]">
            <AnimatedText />
          </div>
        )}
      </Card>

      {/* Create Team Modal */}
      {isCreateTeamModalOpen && (
        <CreateTeam isOpen={isCreateTeamModalOpen} onClose={handleCloseCreateTeam} />
      )}

      {/* Search Modal for adding members */}
      {isModalOpen && <SearchModal onClose={toggleModal} />}
    </>
  );
};

export default UserReportsTable;

import React, { useState } from 'react';
import { MdClose } from 'react-icons/md';
import { motion, AnimatePresence } from 'framer-motion';
import { FiCheckCircle } from 'react-icons/fi';

const EventPopup = ({ event, position, onClose, onBooked }) => {
  // Booking statuses: 'idle', 'loading', 'success', 'error'
  const [bookingStatus, setBookingStatus] = useState('idle');
  const [errorMessage, setErrorMessage] = useState('');

  if (!event) return null;

  const handleAttendClick = async () => {
    setBookingStatus('loading');
    setErrorMessage('');

    try {
      // Simulate an API call delay (replace with real API call)
      await new Promise(resolve => setTimeout(resolve, 1200));
      console.log("Booking event:", event.id);
      setBookingStatus('success');

      // Call onBooked callback if provided to update the event's booked status on the calendar
      if (onBooked) {
        onBooked(event.id);
      }

      // Auto close after a brief delay to show success message
      setTimeout(() => {
        onClose();
        setBookingStatus('idle');
      }, 1500);
    } catch (error) {
      setBookingStatus('error');
      setErrorMessage('There was an error booking the event. Please try again.');
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.9 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0, scale: 0.9 }}
      transition={{ duration: 0.2, ease: "easeOut" }}
      className="absolute bg-white dark:bg-navy-700 shadow-xl rounded-xl p-5 w-96 border border-gray-200 dark:border-gray-600 transition-transform z-50"
      style={{ top: position.top + 20, left: position.left + 20 }}
      role="dialog"
      aria-modal="true"
      aria-labelledby="event-title"
    >
      <div className="relative">
        <img
          src={event.imageUrl || 'https://via.placeholder.com/400'}
          alt={event.title}
          className="w-full h-48 object-cover rounded-t-xl shadow-md"
        />
        <div className="absolute top-2 right-2 bg-orange-500 text-white px-3 py-1 text-xs font-bold rounded-md">
          Upcoming
        </div>
        {/* Close Button */}
        <button 
          onClick={onClose}
          className="absolute top-2 left-2 text-gray-500 hover:text-gray-700 focus:outline-none"
          aria-label="Close"
        >
          <MdClose size={20} />
        </button>
      </div>
      
      <div className="mt-4">
        <h3 id="event-title" className="text-2xl font-bold text-navy-700 dark:text-white">
          {event.title}
        </h3>
        
        <div className="flex items-center mt-2 text-gray-600 dark:text-gray-300 text-sm">
          <span className="mr-2" aria-hidden="true">📅</span>
          <p>{event.date}</p>
        </div>

        <div className="flex items-center mt-2 text-gray-600 dark:text-gray-300 text-sm">
          <span className="mr-2" aria-hidden="true">🕒</span>
          <p>{event.time}</p>
        </div>

        <div className="flex items-center mt-2 text-gray-600 dark:text-gray-300 text-sm">
          <span className="mr-2" aria-hidden="true">📍</span>
          <p>{event.location}</p>
        </div>
      </div>

      <div className="mt-4 flex justify-end">
        <AnimatePresence exitBeforeEnter>
          {bookingStatus === 'success' ? (
            <motion.div
              initial={{ scale: 0.8, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.8, opacity: 0 }}
              transition={{ duration: 0.3 }}
              className="flex items-center gap-2 bg-green-500 text-white font-bold py-2 px-4 rounded-lg shadow-md"
            >
              <FiCheckCircle size={20} />
              <span>You're in!</span>
            </motion.div>
          ) : (
            <motion.button
              whileTap={{ scale: 0.95 }}
              onClick={handleAttendClick}
              disabled={bookingStatus === 'loading'}
              className={`bg-orange-500 hover:bg-orange-600 text-white font-bold py-2 px-4 rounded-lg transition duration-200 ${
                bookingStatus === 'loading' ? 'opacity-50 cursor-not-allowed' : ''
              }`}
              aria-busy={bookingStatus === 'loading'}
            >
              {bookingStatus === 'loading' ? 'Processing...' : 'RSVP'}
            </motion.button>
          )}
        </AnimatePresence>
      </div>
      {bookingStatus === 'error' && (
        <div className="mt-2 text-red-500 text-sm">
          {errorMessage}
        </div>
      )}
    </motion.div>
  );
};

export default EventPopup;

// YourTransfers.js
import React, { useEffect, useState } from "react";
import { BsArrowRight } from "react-icons/bs";
import { FaUserPlus } from "react-icons/fa";
import Card from "components/card";
import { fetchTeamMembers, getUserProfile } from "api";
import { useNavigate } from "react-router-dom";

function YourTransfers() {
  const [teamMembers, setTeamMembers] = useState([]);
  const [userProfile, setUserProfile] = useState(null);
  const navigate = useNavigate();

  const baseURL = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    const fetchProfileAndMembers = async () => {
      try {
        const profile = await getUserProfile();
        if (profile && profile.user) {
          setUserProfile(profile.user);

          // Ensure the teamId is available and fetch team members
          if (profile.user.team?.id) {
            const members = await fetchTeamMembers(profile.user.team.id);

            // Filter out the current user from the team members
            const filteredMembers = members.filter(
              (member) => member.id !== profile.user.id
            );
            setTeamMembers(filteredMembers);
          } else {
            console.warn("User is not part of any team.");
          }
        } else {
          console.warn("Failed to fetch user profile.");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchProfileAndMembers();
  }, []);

  const handleChatClick = (member) => {
    navigate(`/admin/inbox?chatWith=${member.id}`);
  };

  const handleViewMore = () => {
    navigate('/admin/teams'); // Adjust the path to your team page
  };

  return (
    <Card extra={"p-4 h-full"}>
      <h4 className="mb-6 text-xl font-bold text-navy-700 dark:text-white">
        Team Members
      </h4>
      {teamMembers.length > 0 ? (
        <div className="space-y-4">
          {teamMembers.slice(0, 3).map((member) => (
            <div
              key={member.id}
              className="flex items-center justify-between p-3 bg-gray-50 dark:bg-navy-700 rounded-lg shadow-sm"
            >
              <div className="flex items-center">
              <img
                src={
                  member.imageUrl
                    ? (member.imageUrl.startsWith("http")
                        ? member.imageUrl
                        : `${baseURL.replace(/\/$/, "")}/${member.imageUrl.replace(/^\/uploads/, "uploads/images")}`)
                    : "https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y&s=100"
                }
                alt={`${member.firstName} ${member.lastName}`}
                className="w-12 h-12 rounded-full object-cover"
                onError={(e) => {
                  e.target.onerror = null; // Prevent infinite loop
                  e.target.src =
                    "https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y&s=100";
                }}
              />
                <div className="ml-4">
                  <h5 className="text-md font-semibold text-navy-700 dark:text-white">
                    {member.firstName} {member.lastName}
                  </h5>
                  <p className="text-sm text-gray-600 dark:text-gray-300">
                    {member.role || "Team Member"}
                  </p>
                </div>
              </div>
              <button
                className="rounded-xl bg-gradient-to-br from-[#833AB4] via-[#FD1D1D] to-[#FCB045] px-2 py-1 text-base font-medium text-white transition duration-200 hover:shadow-lg hover:shadow-[#FD1D1D]/50"
                onClick={() => handleChatClick(member)}
              >
                Chat
              </button>
            </div>
          ))}
          {teamMembers.length > 3 && (
            <div className="flex justify-center mt-4">
              <button
                className="text-sm font-bold text-brand-500 dark:text-white flex items-center hover:underline"
                onClick={handleViewMore}
              >
                View More
                <BsArrowRight className="ml-1" />
              </button>
            </div>
          )}
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center h-full text-center text-navy-700 dark:text-white">
          <FaUserPlus className="text-6xl text-orange-500 mb-4" />
          <p className="text-lg font-semibold">
            You're not part of a team yet
          </p>
          <p className="text-gray-600 dark:text-gray-400 mt-2">
            Join or create a team to start collaborating!
          </p>
          <button
            className="mt-6 px-6 py-2 rounded-full border border-gray-300 text-gray-700 hover:border-orange-400 dark:border-gray-600 dark:text-white dark:hover:border-orange-400 font-medium transition-colors duration-200 transform hover:scale-105"
            onClick={() => navigate('/admin/teams')}
          >
            Find a Team
          </button>
        </div>
      )}
    </Card>
  );
}

export default YourTransfers;

import Card from "components/card";
import Completed from "./components/Completed";
import CourseInfo from "./components/CourseInfo";
import { useLocation } from "react-router-dom";
import { getUserProfile } from "api";
import { useEffect, useState } from "react";


function CoursePage() {
  const location = useLocation();
  const {state} = location;

  const poap = state?.poap || {
    id: null,
    title: "No POAP selected",
    description: "No description available.",
    learnings: "No learnings available",
    totalPoints: 0,
    categories: [],
    resourceLink: null,
    videoPath: "/videos/default.mp4"
  }

  const videoPath = poap.videoPath || `/videos/NFTVids/${poap.title}.mp4`;



  const poapIndex = state?.poapIndex || 0;

  const [teamId, setTeamId] = useState(null);
  const [school, setSchool] = useState(null);
  const [userId, setUserId] = useState(null);
  const [mentor, setMentor] = useState(null);

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const profile = await getUserProfile();
        setTeamId(profile.user.team?.id);
        setSchool(profile.user.school);
        setUserId(profile.user.id);
        setMentor(profile.user.mentor);
      } catch (error) {
        console.error("Error fetching user profile", error)
      }
    }

    fetchProfile();
  }, []);

  return (
    <div className="mt-3 grid w-full grid-cols-1 gap-5 font-dm lg:grid-cols-11">
      <div className="h-full w-full rounded-[20px] lg:col-span-7 4xl:col-span-8">
        {/* iframe */}
        <Card extra={"w-full mb-5"}>
          <video className="w-full rounded-[20px]" autoPlay loop muted playsInline>
            <source src={videoPath} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </Card>
        {/* Course info */}
        <CourseInfo description={poap.description} totalPoints={poap.totalPoints} mentor={mentor} resourceLink={poap.resourceLink} userId={userId}/>
      </div>

      {/* right section */}
      <div className="lg:col-span-4 4xl:col-span-3">
        <Completed poap= {poap} teamId={teamId}  school={school}  userId={userId} poapIndex={poapIndex}/>
      </div>
    </div>
  );
}

export default CoursePage;

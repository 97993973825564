import {jwtDecode} from 'jwt-decode';

export function saveToken(token, rememberMe) {
  localStorage.setItem('token', token);
  if (rememberMe) {
    localStorage.setItem('rememberMe', 'true');
  } else {
    localStorage.removeItem('rememberMe');
  }
}

export function getToken() {
  return localStorage.getItem('token');
}

export function removeToken() {
  localStorage.removeItem('token');
  localStorage.removeItem('rememberMe');
}

export function isTokenExpired(token) {
  if (!token) {
    console.log('No token provided.');
    return true;
  }
  try {
    const decodedToken = jwtDecode(token);
    const currentTime = Date.now();
    const expiryTime = decodedToken.exp * 1000; // Convert to milliseconds
    const isExpired = expiryTime < currentTime;
    return isExpired;
  } catch (e) {
    console.error('Error decoding token:', e);
    return true;
  }
}

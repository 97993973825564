import React, { useEffect, useState } from "react";
import Dropdown from "components/dropdown";
import { IoMdNotificationsOutline } from "react-icons/io";
import { BsArrowBarUp, BsInfoCircle, BsExclamationCircle, BsCheckCircle } from "react-icons/bs";
import { io } from "socket.io-client";
import { fetchNotifications, markNotificationAsRead, getUserProfile } from "api";

const Notification = () => {
  const [notifications, setNotifications] = useState([]);
  const [userId, setUserId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [socketError, setSocketError] = useState(null);
  const [hasUnread, setHasUnread] = useState(false);

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const userProfile = await getUserProfile();
        if (userProfile && userProfile.user && userProfile.user.id) {
          setUserId(userProfile.user.id);
          const fetchedNotifications = await fetchNotifications(userProfile.user.id);
          setNotifications(fetchedNotifications);
          setHasUnread(fetchedNotifications.some(notification => !notification.read));
        } else {
          console.error("User ID not found in profile.");
        }
      } catch (error) {
        console.error("Error fetching user profile or notifications:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchUserProfile();

    const socket = io(process.env.REACT_APP_API_BASE_URL);

    socket.on('connect', () => {
      console.log('Connected to server');
      if (userId) {
        socket.emit('joinRoom', userId, (error) => {
          if (error) {
            console.error('Error joining room:', error);
            setSocketError('Failed to join notification room.');
          }
        });
      }
    });

    socket.on('notification', (notification) => {
      setNotifications((prev) => [...prev, notification]);
      setHasUnread(true);
    });

    return () => {
      socket.disconnect();
    };
  }, [userId]);

  const handleMarkAsRead = async (notificationId) => {
    try {
      await markNotificationAsRead(notificationId);
      setNotifications((prev) => prev.map(notif => notif.id === notificationId ? {...notif, read: true} : notif));
      setHasUnread(notifications.some(notification => !notification.read));
    } catch (error) {
      console.error("Error marking notification as read:", error);
    }
  };

  const handleMarkAllAsRead = async () => {
    try {
      await Promise.all(
        notifications.map((notif) => markNotificationAsRead(notif.id))
      );
      setNotifications(prev => prev.map(notif => ({ ...notif, read: true })));
      setHasUnread(false);
    } catch (error) {
      console.error("Error marking all notifications as read:", error);
    }
  };

  const getNotificationIcon = (type) => {
    switch (type) {
      case "team_creation":
        return <BsCheckCircle className="text-green-500" />;
      case "team_role_change":
        return <BsInfoCircle className="text-blue-500" />;
      case "team_points_earned":
        return <BsArrowBarUp className="text-yellow-500" />;
      case "team_creation_failed":
        return <BsExclamationCircle className="text-red-500" />;
      default:
        return <BsInfoCircle />;
    }
  };

  return (
    <Dropdown
      button={
        <div className="relative cursor-pointer">
          <IoMdNotificationsOutline className={`h-5 w-5 ${hasUnread ? "text-orange-500 dark:text-orange-400" : "text-gray-600 dark:text-white"}`} />
          {hasUnread && (
            <span className="absolute -top-1 -right-1 flex h-3 w-3">
              <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-75"></span>
              <span className="relative inline-flex rounded-full h-3 w-3 bg-red-500 dark:bg-red-400"></span>
            </span>
          )}
        </div>
      }
      animation="origin-[65%_0%] md:origin-top-right transition-all duration-300 ease-in-out"
      children={
        <div className="flex w-[280px] flex-col gap-3 rounded-[20px] bg-white p-4 shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:text-white dark:shadow-none sm:w-[460px]">
          <div className="flex items-center justify-between">
            <p className="text-base font-bold text-navy-700 dark:text-white">
              Notifications
            </p>
            {notifications.length > 0 && (
              <button
                onClick={handleMarkAllAsRead}
                className="text-sm font-bold text-navy-700 dark:text-white"
              >
                Mark all as read
              </button>
            )}
          </div>
          {loading ? (
            <p className="text-sm text-gray-700 dark:text-white">
              Loading notifications...
            </p>
          ) : notifications.length === 0 ? (
            <p className="text-sm text-gray-700 dark:text-white">
              No notifications
            </p>
          ) : (
            notifications.map((notification, index) => (
              <button
                key={index}
                onClick={() => handleMarkAsRead(notification.id)}
                className={`flex w-full items-center p-3 rounded-lg transition-all ${
                  notification.read ? 'bg-gray-100 dark:bg-navy-800 opacity-75' : 'bg-white dark:bg-navy-700'
                } hover:bg-gray-200 dark:hover:bg-navy-600`}
              >
                <div className="flex h-full w-[60px] items-center justify-center rounded-lg bg-gradient-to-b from-brand-400 to-brand-500 py-3 text-xl text-white">
                  {getNotificationIcon(notification.type)}
                </div>
                <div className="ml-3 flex h-full w-full flex-col justify-center">
                  <p className="mb-1 text-left text-base font-bold text-gray-900 dark:text-white">
                    {notification.title}
                  </p>
                  <p className="text-left text-sm text-gray-600 dark:text-gray-300">
                    {notification.message}
                  </p>
                </div>
              </button>
            ))
          )}
          {socketError && (
            <p className="mt-2 text-xs text-red-500">
              {socketError}
            </p>
          )}
        </div>
      }
      classNames={"py-2 top-4 -left-[230px] md:-left-[440px] w-max"}
    />
  );
};

export default Notification;

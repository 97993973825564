import React, { useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';
import { FaChevronDown, FaMedal, FaTrophy } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { fetchTeamRankings } from 'api';

const TeamRanking = () => {
  const [teams, setTeams] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // We'll show either 10 or 25 teams initially
  const [limit, setLimit] = useState(10);

  // Hover tooltip state
  const [hoveredTeam, setHoveredTeam] = useState(null);
  const [hoverPosition, setHoverPosition] = useState({ x: 0, y: 0 });

  // For Framer Motion height animation
  const contentRef = useRef(null);
  const [containerHeight, setContainerHeight] = useState(0);

  const navigate = useNavigate();

  // 1) Fetch teams from your actual API endpoint
  useEffect(() => {
    const loadTeams = async () => {
      try {
        const fetchedTeams = await fetchTeamRankings();
        setTeams(fetchedTeams);
      } catch (err) {
        setError('Error fetching team rankings');
      } finally {
        setLoading(false);
      }
    };
    loadTeams();
  }, []);

  // 2) Measure container height (for the expanding animation) whenever limit or teams changes
  useEffect(() => {
    if (contentRef.current) {
      setContainerHeight(contentRef.current.scrollHeight);
    }
  }, [limit, teams]);

  // 3) Determine if the list is expanded to 25 or collapsed to 10
  const isShowingMore = limit === 25;
  const toggleButtonText = isShowingMore ? 'Show Less' : 'Show More';

  // 4) Expand/collapse logic
  const handleExpand = () => {
    setLimit(isShowingMore ? 10 : 25);
  };

  // 5) Only show up to `limit` teams
  const displayedTeams = teams.slice(0, limit);

  // 6) Hover logic (same as in your initial code)
  const handleMouseEnter = (team, event) => {
    setHoveredTeam(team);
    setHoverPosition({ x: event.clientX, y: event.clientY });
  };
  const handleMouseMove = (event) => {
    setHoverPosition({ x: event.clientX, y: event.clientY });
  };
  const handleMouseLeave = () => {
    setHoveredTeam(null);
  };

  // 7) Loading state
  if (loading) {
    return (
      <div className="flex justify-center items-center h-full">
        <div className="text-lg font-semibold text-gray-700 dark:text-gray-300">
          Loading...
        </div>
      </div>
    );
  }

  // 8) Error state
  if (error) {
    return (
      <div className="flex justify-center items-center h-full">
        <div className="text-lg font-semibold text-red-500">
          {error}
        </div>
      </div>
    );
  }

  // 9) No teams
  if (teams.length === 0) {
    return (
      <div className="flex flex-col items-center p-6 bg-white dark:bg-navy-800 rounded-lg shadow-lg dark:shadow-none">
        <FaMedal className="text-6xl text-orange-500 mb-4" />
        <h2 className="mb-2 text-2xl font-bold text-center text-gray-800 dark:text-white">
          No Teams Ranked Yet
        </h2>
        <p className="text-center text-gray-600 dark:text-gray-300 mb-6">
          Be the first to create a team and start earning points to appear on the leaderboard!
        </p>
        <button
          className="px-4 py-2 text-md rounded-full bg-gradient-to-r from-orange-400 to-brand-900 
                     hover:from-brand-900 hover:to-orange-400 text-white font-semibold shadow-lg 
                     transition-all duration-200 transform hover:scale-105"
          onClick={() => navigate('/admin/teams')}
        >
          Create a Team
        </button>
      </div>
    );
  }

  // 10) Render
  return (
    <div className="relative flex flex-col items-center p-4 md:p-6 bg-white dark:bg-navy-800 
                    rounded-lg shadow-lg dark:shadow-none"
    >
      <h2 className="mb-4 text-xl md:text-2xl font-bold text-center text-navy-700 dark:text-white">
        Leaderboard
      </h2>

      {/* Animated container for the table */}
      <motion.div
        className="w-full overflow-hidden"
        animate={{ height: containerHeight }}
        initial={{ height: 0 }}
        transition={{ duration: 0.4 }}
      >
        <div ref={contentRef}>
          <table className="min-w-full bg-white dark:bg-navy-800">
            <thead>
              <tr className="sticky top-0">
                <th className="px-6 py-3 border-b border-gray-200 dark:border-gray-600 
                               bg-gray-50 dark:bg-navy-700 text-left text-xs font-medium 
                               text-gray-500 uppercase tracking-wider"
                >
                  Rank
                </th>
                <th className="px-6 py-3 border-b border-gray-200 dark:border-gray-600 
                               bg-gray-50 dark:bg-navy-700 text-left text-xs font-medium 
                               text-gray-500 uppercase tracking-wider"
                >
                  Team Name
                </th>
                <th className="px-6 py-3 border-b border-gray-200 dark:border-gray-600 
                               bg-gray-50 dark:bg-navy-700 text-left text-xs font-medium 
                               text-gray-500 uppercase tracking-wider"
                >
                  Points
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 dark:divide-gray-700">
              {displayedTeams.map((team, index) => {
                const rank = index + 1;
                return (
                  <tr
                    key={team.id}
                    onMouseEnter={(e) => handleMouseEnter(team, e)}
                    onMouseMove={handleMouseMove}
                    onMouseLeave={handleMouseLeave}
                    className={`hover:bg-gray-100 dark:hover:bg-navy-700 
                      ${rank <= 3 ? 'bg-yellow-50 dark:bg-yellow-900' : ''}`}
                  >
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium 
                                   text-gray-900 dark:text-white"
                    >
                      {rank}
                      {rank === 1 && (
                        <FaTrophy className="inline text-yellow-500 ml-1" />
                      )}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm 
                                   text-gray-900 dark:text-white"
                    >
                      {team.name}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm 
                                   text-gray-900 dark:text-white"
                    >
                      {team.points} pts
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </motion.div>

      {/* Toggle button: Show More / Show Less */}
      {teams.length > 10 && (
        <button
          onClick={handleExpand}
          className="mt-4 flex items-center space-x-2 bg-orange-50 hover:bg-orange-100
                     text-orange-600 dark:text-orange-400 font-semibold py-2 px-4 
                     rounded-full transition-colors duration-300 shadow"
        >
          <FaChevronDown
            className={`transition-transform duration-300 
              ${isShowingMore ? 'transform rotate-180' : ''}`}
          />
          <span>{toggleButtonText}</span>
        </button>
      )}

      {/* If you have more than 25 teams and we’re expanded, show “All Teams” button */}
      {teams.length > 25 && isShowingMore && (
        <button
          onClick={() => navigate('/admin/teams?tab=2')}
          className="mt-4 bg-orange-600 hover:bg-orange-700 text-white font-semibold 
                     py-2 px-6 rounded-full shadow transition-colors duration-300"
        >
          All Teams
        </button>
      )}

      {/* Hover tooltip (shows team.description, team.poaps, team.memberCount) */}
      {hoveredTeam && (
        <div
          className="absolute bg-white dark:bg-navy-800 text-gray-900 dark:text-white p-4 
                     border border-gray-200 dark:border-gray-700 shadow-xl rounded-lg w-64 
                     transition-all duration-200 z-50"
          style={{
            top: hoverPosition.y + 10,
            left: hoverPosition.x + 10,
            // Remove pointerEvents if you want the row to remain highlightable
            // pointerEvents: 'none'
          }}
        >
          <h4 className="font-bold mb-1">{hoveredTeam.name}</h4>
          <p className="text-sm text-gray-600 dark:text-gray-300 mb-2">
            {hoveredTeam.description || 'No description available for this team.'}
          </p>
          <div className="text-sm">
            <p>
              <strong>POAPs:</strong> {hoveredTeam.poaps}
            </p>
            <p>
              <strong>Members:</strong> {hoveredTeam.memberCount}
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default TeamRanking;

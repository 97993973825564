import React, { useEffect, useState } from "react";
import MiniStatistics from "./components/MiniStatistics";
import { MdOutlineBarChart, MdPerson, MdFileCopy, MdSchool } from "react-icons/md";
import UserReportsTable from "./components/UserReportsTable";
import { columnsDataUserReports } from "./variables/columnsDataUserReports";

import {
  getUserProfile,
  fetchTeamMembers,
  fetchTeamRankings,
  fetchPoapDescriptions,
  fetchTeamStepStatus
} from "api";

import Loader from "components/loaders/Loader";

const Application = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggleModal = () => setIsModalOpen(!isModalOpen);

  const [userProfile, setUserProfile] = useState(null);
  const [teamMembers, setTeamMembers] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [rankedTeams, setRankedTeams] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // We'll store the user's "current POAP" (the first incomplete one)
  const [currentPoapTitle, setCurrentPoapTitle] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        // 1. Get user profile & check team
        const profile = await getUserProfile();
        const teamId = profile.user.team?.id;
        setUserProfile(profile);

        // 2. If team exists, fetch team members
        if (teamId) {
          let members = await fetchTeamMembers(teamId);
          members = members.filter((m) => m.id !== profile.user.id);

          const formattedMembers = members.map((m) => ({
            id: m.id,
            name: m.username || "Unknown",
            email: m.email || "No Email",
            teamRole: m.teamRole || "N/A",
            status: m.status || "Offline",
            imageUrl: m.imageUrl,
          }));

          setTeamMembers(formattedMembers);
          setSelectedTeam(profile.user.team);

          // 3. Fetch all POAP Descriptions
          const descriptions = await fetchPoapDescriptions();

          // 4. For each POAP, fetch the step statuses for this team
          //    Then see if it is fully complete or not
          const statusArray = await Promise.all(
            descriptions.map(async (poap) => {
              const stepStatus = await fetchTeamStepStatus(teamId, poap.id);
              // "fully complete" => every step is completed
              const isFullyComplete = stepStatus.every((s) => s.status === "completed");
              return {
                poap,
                isFullyComplete
              };
            })
          );

          // 5. Sort by POAP id (ascending) or any other logic to pick the "first" 
          statusArray.sort((a, b) => a.poap.id - b.poap.id);

          // 6. Find the first POAP that's NOT fully complete
          const firstIncomplete = statusArray.find((item) => !item.isFullyComplete);

          if (firstIncomplete) {
            // There's at least one incomplete POAP, so it's the current level
            setCurrentPoapTitle(firstIncomplete.poap.title);
          } else {
            // All POAPs are done
            setCurrentPoapTitle("All POAPs Completed!");
          }
        }

        // 7. Fetch team rankings
        const teams = await fetchTeamRankings();
        setRankedTeams(teams);
      } catch (err) {
        console.error("Error fetching data:", err);
        setError("Unable to fetch some data, displaying available information.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  // Helper for dynamic rank label
  const getOrdinalSuffix = (rank) => {
    if (!rank || rank < 1) return "Not Ranked";
    const j = rank % 10,
      k = rank % 100;
    if (j === 1 && k !== 11) return `${rank}st`;
    if (j === 2 && k !== 12) return `${rank}nd`;
    if (j === 3 && k !== 13) return `${rank}rd`;
    return `${rank}th`;
  };

  // Derive the team's rank
  const teamRank = selectedTeam
    ? getOrdinalSuffix(rankedTeams.findIndex((t) => t.id === selectedTeam.id) + 1)
    : "Not Ranked";

  // Basic fallback
  const schoolName = userProfile?.user?.school || "School Information Not Available";
  const mentorName = userProfile?.user?.mentor
    ? `${userProfile.user.mentor.firstName} ${userProfile.user.mentor.lastName}`
    : "No Mentor Assigned";

  if (loading) return <Loader />;

  return (
    <div className="mt-3 h-full w-full rounded-[20px]">
      {error && <p className="text-center text-red-500">{error}</p>}

      {/* Stats Cards */}
      <div className="grid grid-cols-1 gap-5 md:grid-cols-2 3xl:grid-cols-4">

        {/* 1) Team Rank */}
        <div>
          <MiniStatistics
            icon={<MdOutlineBarChart className="text-4xl" />}
            title="Team Rank"
            value={selectedTeam ? teamRank : "No Team Assigned"}
            growth={`${selectedTeam ? selectedTeam.points : 0} Club Points`}
            growthColor="text-green-500"
            cardBg="bg-gradient-to-r from-orange-400 to-orange-600"
            titleColor="text-white"
            valueColor="text-white"
            detailColor="text-white"
            iconColor="text-white"
          />
        </div>

        {/* 2) Current POAP Level (first incomplete) */}
        <div>
          <MiniStatistics
            icon={<MdFileCopy className="text-4xl" />}
            title="Current POAP Level"
            value={currentPoapTitle || "No POAP Found"}
            bgColor="bg-white dark:!bg-navy-800"
            growth="In Progress"
            growthColor="text-blue-500"
            cardBg="bg-white"
            titleColor="text-gray-600"
            valueColor="text-navy-700 dark:text-white"
            detailColor="text-gray-600"
            iconColor="text-brand-500"
          />
        </div>

        {/* 3) School Name */}
        <div>
          <MiniStatistics
            icon={<MdSchool className="text-4xl" />}
            title="School Name"
            value={schoolName}
            bgColor="bg-lightPrimary dark:!bg-navy-700"
            growth="Class of 2024"
            growthColor="text-purple-500"
            cardBg="bg-white"
            titleColor="text-gray-600"
            valueColor="text-navy-700 dark:text-white"
            detailColor="text-gray-600"
            iconColor="text-brand-500 dark:text-white"
          />
        </div>

        {/* 4) Mentor */}
        <div>
          <MiniStatistics
            icon={<MdPerson className="text-4xl" />}
            title="Mentor"
            value={mentorName}
            bgColor="bg-lightPrimary dark:bg-navy-700"
            growth="Specialization: 3D Printing"
            growthColor="text-red-500"
            cardBg="bg-white"
            titleColor="text-gray-600"
            valueColor="text-navy-700 dark:text-white"
            detailColor="text-gray-600"
            iconColor="text-brand-500 dark:text-white"
          />
        </div>
      </div>

      {/* Table for team members */}
      <div className="mt-3 ">
        <UserReportsTable
          tableData={
            teamMembers.length > 0
              ? teamMembers
              : [{ name: "No team members found", email: "N/A", teamRole: "N/A", status: "N/A" }]
          }
          columnsData={columnsDataUserReports}
          isModalOpen={isModalOpen}
          toggleModal={toggleModal}
          selectedTeam={selectedTeam}
          userProfile={userProfile}
        />
      </div>
    </div>
  );
};

export default Application;

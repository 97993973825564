import React, { useState, useEffect } from 'react';
import Card from "components/card";
import { FaCalendarPlus } from "react-icons/fa";
import {
  createTimeline,
  fetchUserTimelines,
  getUserProfile
} from "api";

const Event = () => {
  const [events, setEvents] = useState([]);
  const [eventTitle, setEventTitle] = useState("");
  const [eventDate, setEventDate] = useState("");
  const [eventDescription, setEventDescription] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const [mentorId, setMentorId] = useState(null);
  const [userId, setUserId] = useState(null);
  const [userRole, setUserRole] = useState("");

  // Fetch the user profile on mount
  useEffect(() => {
    const loadUserProfile = async () => {
      try {
        const userProfile = await getUserProfile();
        console.log("User profile fetched:", userProfile);
  
        if (userProfile?.user) {
          setUserId(userProfile.user.id);
          setUserRole(userProfile.user.role);
  
          // If the user is a mentor, use their own ID and do not load events.
          if (userProfile.user.role === "mentor") {
            setMentorId(userProfile.user.id);
          } else if (userProfile.user.mentor?.id) {
            // For non-mentor users, load events using the mentor's ID.
            setMentorId(userProfile.user.mentor.id);
            const timelines = await fetchUserTimelines(userProfile.user.mentor.id);
            setEvents(timelines);
          } else {
            setErrorMessage("No mentor assigned. Please contact support.");
          }
        }
      } catch (error) {
        console.error("Error fetching user profile:", error);
        setErrorMessage("Failed to load user profile. Please try again.");
      }
    };
  
    loadUserProfile();
  }, []);
  
  // Handle adding a new event (only for mentors)
  const handleAddEvent = async () => {
    if (eventTitle && eventDate && mentorId) {
      setLoading(true);
      try {
        const newEvent = {
          title: eventTitle,
          startDate: eventDate,
          endDate: eventDate,
          description: eventDescription,
          mentorId,
        };
  
        const response = await createTimeline(newEvent);
        // Optionally, add the newly created event to state:
        setEvents((prev) => [...prev, response]);
  
        // Reset form
        setEventTitle("");
        setEventDate("");
        setEventDescription("");
        setErrorMessage("");
      } catch (error) {
        console.error("Error adding event:", error);
        setErrorMessage("Failed to add event. Please try again.");
      } finally {
        setLoading(false);
      }
    } else {
      setErrorMessage("Please provide both a title and a date for the event.");
    }
  };
  
  return (
    <Card extra="w-full h-full p-5">
      {errorMessage && <p className="text-center text-red-500">{errorMessage}</p>}
      
      {userRole === "mentor" ? (
        <>
          <h4 className="text-xl font-bold text-navy-700 dark:text-white">
            Add New Event
          </h4>
  
          <div className="mt-5 flex flex-col gap-4">
            <input
              type="text"
              placeholder="Event Title"
              value={eventTitle}
              onChange={(e) => setEventTitle(e.target.value)}
              className="w-full rounded-lg border border-gray-300 bg-gray-100 p-3 text-sm font-medium text-navy-700 placeholder-gray-500 outline-none focus:border-orange-500 focus:ring focus:ring-orange-200 dark:bg-navy-700 dark:text-white dark:placeholder-gray-400"
            />
  
            <input
              type="date"
              value={eventDate}
              onChange={(e) => setEventDate(e.target.value)}
              className="w-full rounded-lg border border-gray-300 bg-gray-100 p-3 text-sm font-medium text-navy-700 placeholder-gray-500 outline-none focus:border-orange-500 focus:ring focus:ring-orange-200 dark:bg-navy-700 dark:text-white dark:placeholder-gray-400"
            />
  
            <textarea
              placeholder="Event Description (optional)"
              value={eventDescription}
              onChange={(e) => setEventDescription(e.target.value)}
              className="w-full rounded-lg border border-gray-300 bg-gray-100 p-3 text-sm font-medium text-navy-700 placeholder-gray-500 outline-none focus:border-orange-500 focus:ring focus:ring-orange-200 dark:bg-navy-700 dark:text-white dark:placeholder-gray-400"
              rows="3"
            />
  
            <button
              onClick={handleAddEvent}
              className="flex items-center justify-center rounded-lg bg-orange-500 p-3 text-sm font-bold text-white transition duration-200 hover:bg-orange-600 active:scale-95"
              disabled={loading}
            >
              {loading ? "Adding..." : <FaCalendarPlus className="mr-2" />}
              {loading ? "" : "Add Event"}
            </button>
          </div>
        </>
      ) : (
        // For non-mentor users, remove the upcoming events card entirely.
        <div className="text-center py-6">
          <p className="text-md text-gray-600 dark:text-gray-300">
            View events on the calendar.
          </p>
        </div>
      )}
    </Card>
  );
};
  
export default Event;

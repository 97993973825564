import React, { useState, useEffect } from "react";
import { IoCheckmarkCircle, IoClose } from "react-icons/io5";
import { useMutation } from "@tanstack/react-query";
import { motion } from "framer-motion";
import MpesaLogo from "../../assets/img/ecommerce/Mpesa.png";
import { subscribeToCourse, validateReferralCode } from "api";
import AnimatedCheckmark from "components/dataDisplay/AnimatedCheckmark";

const SubscriptionModal = ({ onClose, parentName, courseId, userId, mentorId }) => {
  const [currency, setCurrency] = useState("KES");
  const [subscriptionType, setSubscriptionType] = useState("monthly");
  const [referralCode, setReferralCode] = useState("");
  const [referenceNumber, setReferenceNumber] = useState("");
  const [subscriptionSuccess, setSubscriptionSuccess] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  const [originalAmount, setOriginalAmount] = useState(10);
  const [discountedAmount, setDiscountedAmount] = useState(10);
  const [finalAmount, setFinalAmount] = useState("10.00");
  const [exchangeRates, setExchangeRates] = useState({});
  const [discountApplied, setDiscountApplied] = useState(false);
  const [schoolSponsored, setSchoolSponsored] = useState(false);

  const [referralVisible, setReferralVisible] = useState(false);
  const [showRefPrompt, setShowRefPrompt] = useState(false);

  const currencyOptions = ["KES", "USD", "EUR", "GBP", "JPY", "INR"];

  // Fetch exchange rates if not already in state
  useEffect(() => {
    const fetchExchangeRates = async () => {
      if (exchangeRates[currency]) return;
      try {
        const response = await fetch(`https://api.exchangerate-api.com/v4/latest/USD`);
        const data = await response.json();
        setExchangeRates({ ...exchangeRates, ...data.rates, USD: 1 });
      } catch (error) {
        console.error("Error fetching exchange rates:", error);
      }
    };

    fetchExchangeRates();
  }, [currency, exchangeRates]);

  // Set base and discounted amounts based on subscription type
  useEffect(() => {
    const baseAmount = subscriptionType === "yearly" ? 10 * 12 : 10;
    const discount = subscriptionType === "yearly" ? baseAmount * 0.7 : baseAmount;
    setOriginalAmount(baseAmount);
    setDiscountedAmount(discount);
    // Reset referral-related states when subscription type changes
    setSchoolSponsored(false);
    setDiscountApplied(false);
  }, [subscriptionType]);

  // Calculate final amount using exchange rates, ensuring discountedAmount is a number.
  useEffect(() => {
    const exchangeRate = exchangeRates[currency] || 1;
    const final = Number(discountedAmount) * exchangeRate;
    setFinalAmount(final.toFixed(2));
  }, [currency, discountedAmount, exchangeRates]);

  // Use discountedAmount directly to determine if we should skip the reference input.
  const skipReference = Number(discountedAmount) === 0;

  // Mutation to validate the referral code.
  // The API is assumed to return both a discountedPrice and a schoolSponsored flag.
  const mutation = useMutation({
    mutationFn: async ({ referralCode, discountedAmount, subscriptionType }) => {
      if (!referralCode) throw new Error("No referral code provided");
      const { discountedPrice, schoolSponsored } = await validateReferralCode(
        referralCode,
        discountedAmount,
        subscriptionType
      );
      return { discountedPrice, schoolSponsored };
    },
    onSuccess: ({ discountedPrice, schoolSponsored }) => {
      // If school sponsored, set discounted amount to 0; otherwise, use the returned discounted price.
      setDiscountedAmount(schoolSponsored ? 0 : discountedPrice);
      setDiscountApplied(true);
      setSchoolSponsored(schoolSponsored);
    },
    onError: () => {
      alert("Invalid or expired referral code.");
      setDiscountApplied(false);
      setSchoolSponsored(false);
    },
  });

  const handleApplyReferralCode = () => {
    if (!referralCode.trim()) {
      alert("Please enter a referral code.");
      return;
    }
    mutation.mutate({ referralCode, discountedAmount, subscriptionType });
  };

  // Confirm subscription. If final price is not 0, we require a reference number.
  const handleConfirmSubscription = async () => {
    if (!skipReference && !referenceNumber.trim()) {
      alert("Please enter the payment reference number.");
      return;
    }
    setIsProcessing(true);
    try {
      await subscribeToCourse({
        userId,
        courseId,
        mentorId,
        referenceNumber: skipReference ? undefined : referenceNumber,
        subscriptionType,
      });
      setSubscriptionSuccess(true);
    } catch (error) {
      console.error("Error during subscription confirmation:", error);
      alert("Error processing your subscription. Please try again.");
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 backdrop-blur-sm">
      <div className="relative bg-white dark:bg-navy-800 rounded-xl shadow-lg w-full max-w-lg p-6">
        <button
          onClick={onClose}
          className="absolute top-3 right-3 text-gray-500 hover:text-gray-700 dark:text-gray-300 dark:hover:text-white"
        >
          <IoClose size={24} />
        </button>
        <h2 className="text-xl font-semibold text-center text-navy-700 dark:text-white">
          Subscribe to {parentName}
        </h2>

        {subscriptionSuccess ? (
          <div className="flex flex-col items-center mt-6">
            <AnimatedCheckmark />
            <p className="mt-4 text-center text-gray-700 dark:text-gray-300">
              Subscription successful! Your transaction is under review.
            </p>
            <button
              onClick={onClose}
              className="w-full mt-4 py-3 text-white bg-orange-600 rounded-md hover:bg-orange-900 transition dark:bg-navy-800 dark:hover:bg-navy-600"
            >
              Close
            </button>
          </div>
        ) : (
          <>
            <div className="mt-6 flex justify-center space-x-4">
              {["monthly", "yearly"].map((type) => (
                <button
                  key={type}
                  className={`px-4 py-2 rounded-md ${
                    subscriptionType === type
                      ? "bg-orange-500 text-white"
                      : "bg-gray-200 dark:bg-navy-700 dark:text-white"
                  }`}
                  onClick={() => setSubscriptionType(type)}
                >
                  {type.charAt(0).toUpperCase() + type.slice(1)}
                </button>
              ))}
            </div>

            <div className="mt-6 flex justify-between items-center">
              {subscriptionType === "yearly" && (
                <p className="text-gray-500 line-through">
                  {currency} {originalAmount}
                </p>
              )}
              <motion.div
                className="text-3xl font-bold text-navy-700 dark:text-white"
                initial={{ opacity: 0.9 }}
                animate={{ opacity: 1 }}
              >
                {currency} {finalAmount}
              </motion.div>
              <select
                value={currency}
                onChange={(e) => setCurrency(e.target.value)}
                className="py-2 px-3 border rounded-md bg-gray-100 dark:bg-navy-700 dark:text-white"
              >
                {currencyOptions.map((curr) => (
                  <option key={curr} value={curr}>
                    {curr}
                  </option>
                ))}
              </select>
            </div>

            <div className="mt-6">
              <h3 className="text-lg font-medium text-navy-700 dark:text-white">
                Payment Details
              </h3>
              <div className="mt-4 bg-gray-50 dark:bg-navy-700 p-4 rounded-md flex justify-between">
                <div>
                  <p>
                    Paybill: <strong>880100</strong>
                  </p>
                  <p>
                    Account: <strong>072370</strong>
                  </p>
                </div>
                <img src={MpesaLogo} alt="Mpesa" className="h-8" />
              </div>
            </div>

            <div className="mt-6">
              <button
                className="text-orange-500 hover:underline"
                onClick={() => setReferralVisible(!referralVisible)}
              >
                {referralVisible ? "Hide Referral Code" : "Have a referral code?"}
              </button>
              {referralVisible && (
                <div className="mt-2 space-y-2">
                  <input
                    type="text"
                    value={referralCode}
                    onChange={(e) => setReferralCode(e.target.value)}
                    placeholder="Enter referral code"
                    className="w-full p-2 border rounded-md dark:bg-navy-900 dark:border-navy-700 dark:text-white"
                  />
                  <button
                    onClick={handleApplyReferralCode}
                    className="w-full py-2 bg-orange-500 text-white rounded-md"
                  >
                    Apply Code
                  </button>
                </div>
              )}
            </div>

            {/* Subscription Button / Payment Reference Prompt */}
            <div className="mt-6">
              {skipReference ? (
                // If final price is 0, skip the payment reference prompt.
                <button
                  onClick={handleConfirmSubscription}
                  disabled={isProcessing}
                  className="w-full py-3 text-white bg-orange-500 rounded-md"
                >
                  {isProcessing ? "Processing..." : "Subscribe"}
                </button>
              ) : (
                <>
                  {showRefPrompt ? (
                    <>
                      <input
                        type="text"
                        value={referenceNumber}
                        onChange={(e) => setReferenceNumber(e.target.value)}
                        placeholder="Enter Payment Reference Number"
                        className="w-full p-2 border rounded-md dark:bg-navy-900 dark:border-navy-700 dark:text-white"
                      />
                      <button
                        onClick={handleConfirmSubscription}
                        disabled={isProcessing}
                        className="w-full py-3 mt-4 text-white bg-orange-500 rounded-md"
                      >
                        {isProcessing ? "Processing..." : "Confirm Payment"}
                      </button>
                    </>
                  ) : (
                    <button
                      onClick={() => setShowRefPrompt(true)}
                      className="w-full py-3 text-white bg-orange-500 rounded-md"
                    >
                      Subscribe
                    </button>
                  )}
                </>
              )}
            </div>

            <button
              onClick={onClose}
              className="w-full py-3 mt-4 text-gray-500 dark:text-gray-300"
            >
              Cancel
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default SubscriptionModal;

import { useQuery } from "@tanstack/react-query";
import { fetchCoursesSubStatusByUserId } from "../api";

export const useSubscriptionStatus = (userId) => {
  return useQuery({
    queryKey: ["subscriptionStatus", userId],
    queryFn: () => fetchCoursesSubStatusByUserId(userId),
    staleTime: 1000 * 60 * 10, // 10 minutes
    cacheTime: 1000 * 60 * 30, // 30 minutes 
    enabled: !!userId,
  });
};

/* eslint-disable */

import { HiX } from "react-icons/hi";
import Links from "./components/Links";
import { Link } from "react-router-dom";
import logo from "../../assets/img/dashboards/Logo iForge-8.png";
import SidebarCard from "components/sidebar/components/SidebarCard";
import {
  renderThumb,
  renderTrack,
  renderView,
  renderViewMini,
} from "components/scrollbar/Scrollbar";
import { Scrollbars } from "react-custom-scrollbars-2";
import md5 from "md5"; // Import md5 for Gravatar
import routes from "routes";
import Card from "components/card";
import { getUserProfile } from "api";
import { useEffect, useState } from "react";
import { useAuth } from "contexts/authContext";
import { useQuery } from "@tanstack/react-query";


const SERVER_BASE_URL = process.env.REACT_APP_API_BASE_URL;

// Function to generate Gravatar URL
const getGravatarUrl = (email) => {
  const hash = md5(email.trim().toLowerCase());
  return `https://www.gravatar.com/avatar/${hash}?d=identicon`;
};

function SidebarHorizon(props) {
  const { open, onClose, variant, mini, hovered, setHovered } = props;

  const { user: authUser } = useAuth(); // Get the user object from the auth context

  const { data: userProfileData, isLoading, error } = useQuery({
    queryKey: ['userProfile'],
    queryFn: getUserProfile,
    refetchInterval: 3000, 
    refetchOnWindowFocus: true, 
  });

  const user = userProfileData?.user;

  const getUserImageUrl = (user) => {
    if (user?.imageUrl) {
      // Ensure `/uploads/` is replaced with `uploads/images/`
      const correctedImageUrl = user.imageUrl.replace(/^\/uploads/, "uploads/images");
  
      const finalImageUrl = `${SERVER_BASE_URL.replace(/\/$/, "")}/${correctedImageUrl}`;
      
      console.log("FIXED SIDEBAR IMAGE URL:", finalImageUrl); // Debugging
      return finalImageUrl;
    } else {
      return getGravatarUrl(user?.email || '');
    }
  };
  


  const renderLinks = (routes) => {
    return routes.map((prop, key) => {
      if (
        prop.layout === "/admin" &&
        (!prop.requiredPermission ||
          (Array.isArray(prop.requiredPermission)
            ? prop.requiredPermission.some(permission =>
                authUser.permissions.includes(permission)
              )
            : authUser.permissions.includes(prop.requiredPermission)))
      ) {
        return (
          <li key={key}>
            <Links mini={mini} hovered={hovered} routes={[prop]} onClose={() => onClose()}/>
          </li>
        );
      }
      if (prop.collapse) {
        return renderLinks(prop.items);
      }
      return null;
    });
  };

  if (isLoading) {
    return null; // Or render a loading indicator
  }
  
  if (error) {
    console.error('Error fetching user profile:', error);
    return null; // Or render an error message
  }

  return (
    <div
      className={`sm:none ${
        mini === false
          ? "w-[285px]"
          : mini === true && hovered === true
          ? "w-[285px]"
          : "w-[285px] xl:!w-[120px]"
      } duration-175 linear fixed !z-50 min-h-full transition-all md:!z-50 lg:!z-50 xl:!z-0 ${
        variant === "auth" ? "xl:hidden" : "xl:block"
      } ${open ? "" : "-translate-x-[105%]"} `}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <Card extra={`ml-3 w-full h-[96.5vh] sm:mr-4 sm:my-4 m-7 !rounded-[20px]`}>
        <Scrollbars
          autoHide
          renderTrackVertical={renderTrack}
          renderThumbVertical={renderThumb}
          renderView={
            mini === false
              ? renderView
              : mini === true && hovered === true
              ? renderView
              : renderViewMini
          }
        >
          <div className="flex h-full flex-col justify-between overflow-y-auto scrollbar-hide">
            <div>
              <span
                className="absolute top-4 right-4 block cursor-pointer xl:hidden"
                onClick={onClose}
              >
                <HiX />
              </span>
              <div className={`ml-[52px] mt-[44px] flex items-center `}>
                <Link to={user?.role === 'mentor' ? "/admin/mentor-hub" : "/admin/dashboard"}>
                  <div
                    className={`mt-1 ml-1 h-2.5 font-bold uppercase text-navy-700 dark:text-white ${
                      mini === false
                        ? "block"
                        : mini === true && hovered === true
                        ? "block"
                        : "hidden"
                    }`}
                  >
                    <img src={logo} alt="Logo" className="inline-block h-12 w-auto" />
                  </div>
                </Link>
                <div
                  className={`mt-1 ml-1 h-2.5 font-poppins text-[26px] font-bold uppercase text-navy-700 dark:text-white ${
                    mini === false
                      ? "hidden"
                      : mini === true && hovered === true
                      ? "hidden"
                      : "block"
                  }`}
                >
                </div>
              </div>
              <div className="mt-[58px] mb-7 h-px bg-gray-200 dark:bg-white/10" />

              {/* Nav item */}
              <ul>
                {renderLinks(routes)}
              </ul>
            </div>
            {/* Free Horizon Card */}
            <div className="mt-[28px] mb-[30px]">
              <div className="flex justify-center">
                <SidebarCard mini={mini} hovered={hovered} userProfile={userProfileData}/>
              </div>
              {/* Sidebar profile info */}
              <div className="mt-5 flex items-center justify-center gap-3">
                <div className="h-12 w-12 rounded-full bg-blue-200">
                  <img
                    src={
                      user?.imageUrl
                        ? getUserImageUrl(user) // Ensure full URL is passed for profile image
                        : getGravatarUrl(user?.email || '') // Use Gravatar as fallback
                    }
                    className="rounded-full h-full w-full object-cover"
                    alt="avatar"
                  />
                </div>
                <div
                  className={`ml-1 ${
                    mini === false
                      ? "block"
                      : mini === true && hovered === true
                      ? "block"
                      : "block xl:hidden"
                  }`}
                >
                  {user ? (
                    <>
                      <h4 className="text-base font-bold text-navy-700 dark:text-white">
                        {user.firstName} {user.lastName}
                      </h4>
                      <p className="text-sm font-medium text-gray-600 break-words max-w-[180px]">
                        {user.teamRole}
                      </p>
                    </>
                  ) : (
                    <>
                      <h4 className="text-base font-bold text-navy-700 dark:text-white">
                        Loading...
                      </h4>
                      <p className="text-sm font-medium text-gray-600">
                        Please wait
                      </p>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Scrollbars>
      </Card>
    </div>
  );
}

export default SidebarHorizon;

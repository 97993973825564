import React, { useEffect, useState } from "react";
import Dropdown from "components/dropdown";
import { FiAlignJustify } from "react-icons/fi";
import { Link } from "react-router-dom";
import { RiMoonFill, RiSunFill } from "react-icons/ri";
import { FiSearch } from "react-icons/fi";
import { IoMdNotificationsOutline, IoMdInformationCircleOutline } from "react-icons/io";
import md5 from "md5"; // Import md5 for Gravatar
import { IoChatboxEllipses } from "react-icons/io5";
import MessageDropdown from "./MessageDropdown";
import Notification from "./Notification";
import { getUserProfile } from "api";
import { useTheme } from "layouts/hooks/useTheme";
import { useQuery } from "@tanstack/react-query";
import { useAuth } from "contexts/authContext";


const SERVER_BASE_URL = process.env.REACT_APP_API_BASE_URL;

// Function to generate Gravatar URL
const getGravatarUrl = (email) => {
  const hash = md5(email.trim().toLowerCase());
  return `https://www.gravatar.com/avatar/${hash}?d=identicon`;
};

const Navbar = (props) => {
  const { onOpenSidenav, brandText, mini, hovered } = props;
  const { theme, toggleTheme } = useTheme();
  const { logout } = useAuth();
  

  /* useEffect(() => {
    console.log("Navbar re-rendered with theme:", theme);

    const fetchProfile = async () => {
      try {
        const profile = await getUserProfile();
        setUser(profile.user);
      } catch (error) {
        console.error("Error fetching user profile:", error);
      }
    };

    fetchProfile();
  }, [theme]);

  const handleToggleTheme = () => {
    toggleTheme();
    console.log("Theme toggled. New theme:", theme === "dark" ? "light" : "dark");
  }; */
   // Fetch user profile using React Query with proper queryKey and queryFn
   const { data: profileData, isLoading, error } = useQuery({
    queryKey: ['userProfile'],
    queryFn: getUserProfile,
    refetchInterval: 5000, // Optional: adjust as needed
    refetchOnWindowFocus: true, // Refetch when window regains focus
  });

  const user = profileData?.user;

  const handleToggleTheme = () => {
    toggleTheme();
    console.log('Theme toggled. New theme:', theme === 'dark' ? 'light' : 'dark');
  };

  const handleLogout = () => {
    logout();
  } 


  const getUserImageUrl = (user) => {
    if (user?.imageUrl) {
      // Ensure `/uploads/` is replaced with `uploads/images/` if necessary
      const correctedImageUrl = user.imageUrl.replace(/^\/uploads/, "uploads/images");
  
      const finalImageUrl = `${SERVER_BASE_URL.replace(/\/$/, "")}/${correctedImageUrl}`;
      
      console.log("FIXED NAVBAR IMAGE URL:", finalImageUrl); // Debugging
      return finalImageUrl;
    } else {
      return getGravatarUrl(user?.email || '');
    }
  };
  

  return (
    <nav
      className={`duration-175 linear fixed top-3 right-3 flex flex-row flex-wrap items-center justify-between rounded-xl bg-white/30 transition-all ${
        mini === false
          ? "w-[calc(100vw_-_6%)] md:w-[calc(100vw_-_8%)] lg:w-[calc(100vw_-_6%)] xl:w-[calc(100vw_-_350px)] 2xl:w-[calc(100vw_-_365px)]"
          : mini === true && hovered === true
          ? "w-[calc(100vw_-_6%)] md:w-[calc(100vw_-_8%)] lg:w-[calc(100vw_-_6%)] xl:w-[calc(100vw_-_350px)] 2xl:w-[calc(100vw_-_365px)]"
          : "w-[calc(100vw_-_6%)] md:w-[calc(100vw_-_8%)] lg:w-[calc(100vw_-_6%)] xl:w-[calc(100vw_-_180px)] 2xl:w-[calc(100vw_-_195px)]"
      }  p-2 backdrop-blur-xl dark:bg-[#0b14374d] md:top-4 md:right-[30px] xl:top-[20px]`}
    >
      <div className="ml-[6px]">
        <div className="h-6 w-[224px] pt-1">
          <a
            className="text-sm font-normal text-navy-700 hover:underline dark:text-white dark:hover:text-white"
            href=" "
          >
            Pages
            <span className="mx-1 text-sm text-navy-700 hover:text-navy-700 dark:text-white">
              {" "}
              /{" "}
            </span>
          </a>
          <Link
            className="text-sm font-normal capitalize text-navy-700 hover:underline dark:text-white dark:hover:text-white"
            to="#"
          >
            {brandText}
          </Link>
        </div>
        <p className="shrink text-[33px] capitalize text-navy-700 dark:text-white">
          <Link
            to="#"
            className="font-bold capitalize hover:text-navy-700 dark:hover:text-white"
          >
            {brandText}
          </Link>
        </p>
      </div>

      <div className="relative mt-[3px] flex h-[61px] w-[355px] flex-grow items-center justify-around gap-2 rounded-full bg-white px-2 py-2 shadow-xl shadow-shadow-500 dark:!bg-navy-800 dark:shadow-none md:w-[365px] md:flex-grow-0 md:gap-1 xl:w-[365px] xl:gap-2">
        <div className="flex h-full items-center rounded-full bg-lightPrimary text-navy-700 dark:bg-navy-900 dark:text-white xl:w-[225px]">
          <p className="pl-3 pr-2 text-xl">
            <FiSearch className="h-4 w-4 text-gray-400 dark:text-white" />
          </p>
          <input
            type="text"
            placeholder="Search..."
            className="block h-full w-full rounded-full bg-lightPrimary text-sm font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:!text-white sm:w-fit"
          />
        </div>
        <span
          className="flex cursor-pointer text-xl text-gray-600 dark:text-white xl:hidden "
          onClick={onOpenSidenav}
        >
          <FiAlignJustify className="h-5 w-5" />
        </span>

        <Notification />
        <MessageDropdown />

        <div
        className="cursor-pointer text-gray-600 dark:text-white"
        onClick={handleToggleTheme}
      >
        {theme === "dark" ? (
          <RiSunFill className="h-5 w-5" />
        ) : (
          <RiMoonFill className="h-5 w-5" />
        )}
      </div>

        <Dropdown
          button={
            <img
              className="h-10 w-10 rounded-full object-cover"
              src={getUserImageUrl(user)}
              alt="User Avatar"
            />
          }
          children={
            <div className="flex h-max w-56 flex-col justify-start rounded-[20px] bg-white bg-cover bg-no-repeat pb-4 shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:text-white dark:shadow-none">
              <div className="mt-3 ml-4">
                <div className="flex items-center gap-2">
                  <p className="text-sm font-bold text-navy-700 dark:text-white">
                    👋 Hey, {user?.username}
                  </p>{" "}
                </div>
              </div>
              <div className="mt-3 h-px w-full bg-gray-200 dark:bg-white/20 " />

              <div className="mt-3 ml-4 flex flex-col">
                <Link
                  to="/admin/settings"
                  className="text-sm text-gray-800 dark:text-white hover:dark:text-white"
                >
                  Profile Settings
                </Link>
                {user?.role !== 'mentor' && (
                  <Link
                    to="/admin/billing"
                    className="mt-3 text-sm text-gray-800 dark:text-white hover:dark:text-white"
                  >
                    Billing
                  </Link>
                )}
                <button
                  onClick={handleLogout}
                  className="mt-3 text-sm text-left font-medium text-red-400 hover:text-red-600"
                  style={{ display: "block", width: "100%" }}
                >
                  Log Out
                </button>
              </div>
            </div>
          }
          classNames={"py-2 top-8 -left-[180px] w-max"}
        />
      </div>
    </nav>
  );
};

export default Navbar;

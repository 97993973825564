import React, { useState } from 'react';
import FullCalendar from '@fullcalendar/react'; // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin!
import interactionPlugin from '@fullcalendar/interaction';
import EventPopup from 'components/card/EventPopup'; // needed for eventClick popup
import { useMediaQuery } from 'react-responsive';
import './customFullCalendarTheme.css';
import groot from 'assets/img/avatars/goort.jpg';

function EventCalendar(props) {
  const { calendarData, initialDate } = props;
  // Use local state so we can update events when one is booked.
  const [eventsData, setEventsData] = useState(calendarData);
  const [clickedEvent, setClickedEvent] = useState(null);
  const [popupPosition, setPopupPosition] = useState({ top: 0, left: 0 });

  // Define breakpoints
  const isSmallScreen = useMediaQuery({ query: '(max-width: 768px)' });
  const isMediumScreen = useMediaQuery({ query: '(min-width: 769px) and (max-width: 1024px)' });
  const isLargeScreen = useMediaQuery({ query: '(min-width: 1025px)' });

  const headerToolbarConfig = () => {
    if (isSmallScreen) {
      return { left: 'prev,next', center: 'title', right: '' };
    } else if (isMediumScreen) {
      return { left: 'prev,next today', center: 'title', right: '' };
    } else if (isLargeScreen) {
      return { left: 'prev,next today', center: 'title', right: '' };
    }
  };

  // Custom event rendering to signify a booked event
  const renderEventContent = (eventInfo) => {
    return (
      <div>
        <div className="fc-event-title">{eventInfo.event.title}</div>
        {eventInfo.event.extendedProps.isBooked && (
          <div style={{ fontSize: '0.75rem', color: '#00AA00', fontWeight: 'bold' }}>
            Booked
          </div>
        )}
      </div>
    );
  };

  // Callback for marking an event as booked
  const handleBookedEvent = (eventId) => {
    setEventsData((prevEvents) =>
      prevEvents.map((ev) =>
        ev.id === eventId
          ? {
              ...ev,
              extendedProps: {
                ...ev.extendedProps,
                isBooked: true,
              },
            }
          : ev
      )
    );
  };

  // Event click handler for showing the popup
  const handleEventClick = (info) => {
    info.jsEvent.preventDefault(); // Prevent default browser behavior
    const rect = info.el.getBoundingClientRect();
    const eventDate = new Date(info.event.start);

    // Format the date as "Monday 1, 2024" (without month)
    const formattedDate = `${eventDate.toLocaleDateString('en-US', {
      weekday: 'long',
    })} ${eventDate.getDate()}, ${eventDate.getFullYear()}`;

    // Define an approximate popup height and width (w-96 equals ~384px)
    const popupHeight = 400;
    const popupWidth = 384;
    const margin = 20;

    // Calculate the default top position (20px below the event)
    let topPosition = rect.top + window.scrollY + 20;
    if (rect.bottom + popupHeight > window.innerHeight) {
      topPosition = rect.top + window.scrollY - popupHeight - 20;
      if (topPosition < window.scrollY + 20) {
        topPosition = window.scrollY + 20;
      }
    }

    // Calculate the left position and adjust if it overflows the viewport.
    let leftPosition = rect.left + window.scrollX;
    if (leftPosition + popupWidth > window.innerWidth) {
      leftPosition = window.innerWidth - popupWidth - margin;
    }

    setClickedEvent({
      title: info.event.title,
      date: formattedDate,
      time: info.event.extendedProps.time || '09:00 AM - 12:00 PM',
      location: info.event.extendedProps.location || 'iForge Center',
      imageUrl: info.event.extendedProps.imageUrl || groot,
      id: info.event.id,
    });

    setPopupPosition({ top: topPosition, left: leftPosition });
  };

  // Function to manually close the popup
  const handlePopupClose = () => {
    setClickedEvent(null);
  };

  return (
    <>
      <FullCalendar
        plugins={[dayGridPlugin, interactionPlugin]}
        headerToolbar={headerToolbarConfig()}
        dayMaxEventRows={3}
        initialView="dayGridMonth"
        contentHeight="auto"
        events={eventsData}
        initialDate={initialDate}
        editable={true}
        eventClick={handleEventClick} // Using click instead of hover
        eventContent={renderEventContent}
        height="100%"
        className="dark:bg-navy-800 dark:text-white custom-calendar"
      />
      <EventPopup
        event={clickedEvent}
        position={popupPosition}
        onClose={handlePopupClose}
        onBooked={handleBookedEvent}
      />
    </>
  );
}

export default EventCalendar;

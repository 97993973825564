import React, { useState, useEffect } from "react";
import md5 from "md5";
import avatar from "assets/img/avatars/avatar11.png";
import banner from "assets/img/profile/banner.png";
import Card from "components/card";
import { FiCamera } from "react-icons/fi";
import ErrorAlerts from "components/alerts/ErrorAlerts";
import SuccessAlert from "components/alerts/SuccessAlerts";
import Modal from "components/modal/Modal"; // Modal component
import { getUserProfile } from "api";

const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB

// Function to generate Gravatar URL
const getGravatarUrl = (email) => {
  const hash = md5(email.trim().toLowerCase());
  return `https://www.gravatar.com/avatar/${hash}?d=identicon`;
};

const MentorBanner = ({ onChangeProfilePicture }) => {
  const SERVER_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  const [user, setUser] = useState({
    name: "Loading...",
    role: "iForge Mentor",
    imageUrl: avatar,
  });

  const [errorMessages, setErrorMessages] = useState([]);
  const [successMessage, setSuccessMessage] = useState(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  // Fetch user data on mount
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userProfile = await getUserProfile();
        const email = userProfile?.user?.email || "";
        let imageUrl = avatar;

        if (userProfile?.user?.imageUrl) {
          imageUrl = `${SERVER_BASE_URL.replace(/\/$/, "")}/${userProfile.user.imageUrl.replace(/^\/uploads/, "uploads/images")}?timestamp=${Date.now()}`;
        } else {
          imageUrl = getGravatarUrl(email);
        }

        setUser({
          name: `${userProfile.user.firstName} ${userProfile.user.lastName}`,
          role: "iForge Mentor",
          imageUrl,
        });
      } catch (error) {
        console.error("Error fetching user data:", error);
        setUser((prevUser) => ({
          ...prevUser,
          imageUrl: avatar,
        }));
      }
    };

    fetchUserData();
  }, []);

  // Handle file selection
  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      if (file.size > MAX_FILE_SIZE) {
        setErrorMessages([
          "File is too large. Please upload a file smaller than 5MB.",
        ]);
        return;
      }

      setErrorMessages([]);
      setSelectedFile(file);
      setShowConfirmationModal(true);
    }
  };

  // Confirm profile picture change
  const handleConfirmChange = async () => {
    setShowConfirmationModal(false);

    if (selectedFile) {
      try {
        await onChangeProfilePicture(selectedFile);
        setSuccessMessage("Profile picture updated successfully!");

        // Refresh user data to reflect new profile picture
        const userProfile = await getUserProfile();
        const email = userProfile?.user?.email || "";
        let imageUrl = avatar;

        if (userProfile?.user?.imageUrl) {
          imageUrl = `${SERVER_BASE_URL.replace(/\/$/, "")}/${userProfile.user.imageUrl.replace(/^\/uploads/, "uploads/images")}?timestamp=${Date.now()}`;
        } else {
          imageUrl = getGravatarUrl(email);
        }

        setUser((prevUser) => ({
          ...prevUser,
          imageUrl,
        }));

        // Success message timeout
        setTimeout(() => {
          setSuccessMessage(null);
        }, 5000);
      } catch (error) {
        console.error("Error uploading profile picture:", error);
        setErrorMessages([
          "Failed to upload profile picture. Please try again.",
        ]);
      } finally {
        setSelectedFile(null);
      }
    }
  };

  // Cancel profile picture change
  const handleCancelChange = () => {
    setShowConfirmationModal(false);
    setSelectedFile(null);
  };

  return (
    <div>
      {/* Error Alerts */}
      {errorMessages.length > 0 && (
        <ErrorAlerts errors={errorMessages} onClose={() => setErrorMessages([])} />
      )}
      {/* Success Alert */}
      {successMessage && (
        <SuccessAlert message={successMessage} onClose={() => setSuccessMessage(null)} />
      )}

      {/* Confirmation Modal */}
      {showConfirmationModal && (
        <Modal onClose={handleCancelChange}>
          <div className="p-4">
            <h3 className="text-lg font-semibold mb-4">Confirm Change</h3>
            <p>Are you sure you want to modify your profile picture?</p>
            <div className="mt-6 flex justify-end space-x-4">
              <button
                onClick={handleCancelChange}
                className="px-4 py-2 bg-gray-300 rounded hover:bg-gray-400"
              >
                Cancel
              </button>
              <button
                onClick={handleConfirmChange}
                className="px-4 py-2 bg-brand-900 text-white rounded hover:bg-orange-700"
              >
                Confirm
              </button>
            </div>
          </div>
        </Modal>
      )}

      {/* Mentor Banner Card */}
      <Card extra="items-center w-full h-full p-4 bg-cover">
        <div
          className="relative mt-1 flex h-32 w-full justify-center rounded-xl bg-cover"
          style={{ backgroundImage: `url(${banner})` }}
        >
          <div className="absolute -bottom-12 flex h-[87px] w-[87px] items-center justify-center rounded-full border-[4px] border-white bg-pink-400 dark:!border-navy-700">
            <img
              className="h-full w-full rounded-full object-cover"
              src={user.imageUrl}
              alt="Profile"
            />
            {/* Edit Icon Overlay */}
            <div className="absolute inset-0 flex items-center justify-center rounded-full bg-black bg-opacity-50 opacity-0 hover:opacity-100 transition-opacity cursor-pointer">
              <label
                htmlFor="fileInput"
                className="flex items-center justify-center text-white cursor-pointer"
              >
                <FiCamera size={24} />
              </label>
              <input
                id="fileInput"
                type="file"
                accept="image/*"
                onChange={handleFileChange}
                className="hidden"
              />
            </div>
          </div>
        </div>

        {/* Mentor Information */}
        <div className="mt-16 flex flex-col items-center text-center">
          <h4 className="text-lg font-bold text-navy-700 dark:text-white md:text-xl">
            {user.name}
          </h4>
          <h5 className="text-sm font-normal text-gray-600 dark:text-gray-300 md:text-base">
            {user.role}
          </h5>
        </div>
      </Card>
    </div>
  );
};

export default MentorBanner;

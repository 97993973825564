import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { MdCheck, MdClose, MdEmail, MdAccountBalanceWallet } from 'react-icons/md';

const MintConfirmationModal = ({ registeredEmail, onConfirm, onCancel }) => {
  // If registeredEmail is not available, default to "custom"
  const initialOption = registeredEmail ? 'registered' : 'custom';
  const [selectedOption, setSelectedOption] = useState(initialOption);
  const [customAddress, setCustomAddress] = useState('');

  // Update selected option if registeredEmail becomes unavailable
  useEffect(() => {
    if (!registeredEmail) {
      setSelectedOption('custom');
    }
  }, [registeredEmail]);

  const handleConfirm = () => {
    // If user chose 'registered', use that; otherwise, use custom input
    const address =
      selectedOption === 'registered' ? registeredEmail : customAddress.trim();
    onConfirm(address);
  };

   // Disable confirm button if custom option is selected and input is empty, or if registered option is selected but no email is provided.
   const isConfirmDisabled =
   (selectedOption === 'custom' && customAddress.trim() === '') ||
   (selectedOption === 'registered' && !registeredEmail);

 // Framer Motion variants for the overlay and modal content
 const overlayVariants = {
   hidden: { opacity: 0 },
   visible: { opacity: 1 },
   exit: { opacity: 0 }
 };

 const modalVariants = {
   hidden: { opacity: 0, scale: 0.9 },
   visible: { opacity: 1, scale: 1 },
   exit: { opacity: 0, scale: 0.9 }
 };

  return (
<AnimatePresence>
      <motion.div
        className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-70 backdrop-filter backdrop-blur-md z-50"
        initial="hidden"
        animate="visible"
        exit="exit"
        variants={overlayVariants}
        transition={{ duration: 0.2 }}
      >
        <motion.div
          className="relative w-full max-w-lg p-8 bg-white dark:bg-gray-800 rounded-2xl shadow-2xl transform transition-all duration-300"
          initial="hidden"
          animate="visible"
          exit="exit"
          variants={modalVariants}
          transition={{ duration: 0.2 }}
        >
          <button
            onClick={onCancel}
            aria-label="Close modal"
            className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 focus:outline-none"
          >
            <MdClose size={28} />
          </button>
          <div className="flex flex-col items-center">
            <div className="bg-gradient-to-r from-orange-500 to-pink-500 p-4 rounded-full mb-4 shadow-lg">
              <MdCheck className="text-white text-4xl" />
            </div>
            <h2 className="text-2xl font-bold mb-1 text-gray-800 dark:text-gray-100">
              Confirm Your Mint
            </h2>
            <p className="text-lg text-gray-600 dark:text-gray-300 mb-6">
              Please select the address to receive your POAP mint.
            </p>
            <div className="w-full space-y-4">
              {registeredEmail && (
                <label className="flex items-center p-4 border border-gray-200 dark:border-gray-700 rounded-lg cursor-pointer hover:bg-gray-50 dark:hover:bg-gray-700 transition-colors">
                  <input
                    type="radio"
                    name="mintAddress"
                    value="registered"
                    checked={selectedOption === 'registered'}
                    onChange={() => setSelectedOption('registered')}
                    className="mr-3 accent-orange-500"
                  />
                  <MdEmail className="text-orange-500 mr-2" />
                  <span className="text-gray-700 dark:text-gray-300">
                    Use my registered email: <strong>{registeredEmail}</strong>
                  </span>
                </label>
              )}
              <label className="flex flex-col p-4 border border-gray-200 dark:border-gray-700 rounded-lg cursor-pointer hover:bg-gray-50 dark:hover:bg-gray-700 transition-colors">
                <div className="flex items-center">
                  <input
                    type="radio"
                    name="mintAddress"
                    value="custom"
                    checked={selectedOption === 'custom'}
                    onChange={() => setSelectedOption('custom')}
                    className="mr-3 accent-orange-500"
                  />
                  <MdAccountBalanceWallet className="text-pink-500 mr-2" />
                  <span className="text-gray-700 dark:text-gray-300">
                    {registeredEmail
                      ? "Or, enter a custom email or Ethereum address"
                      : "Enter your email or Ethereum address"}
                  </span>
                </div>
                {selectedOption === 'custom' && (
                  <input
                    type="text"
                    placeholder="Enter email or Ethereum address"
                    value={customAddress}
                    onChange={(e) => setCustomAddress(e.target.value)}
                    className="mt-2 w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-orange-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                  />
                )}
                {selectedOption === 'custom' && customAddress.trim() === '' && (
                  <p className="mt-1 text-sm text-red-500">Please enter a valid address.</p>
                )}
              </label>
            </div>
            <div className="mt-8 flex gap-4">
              <button
                onClick={handleConfirm}
                disabled={isConfirmDisabled}
                className={`px-8 py-3 rounded-full bg-gradient-to-r from-orange-500 to-pink-500 hover:from-orange-600 hover:to-pink-600 transition-colors text-white font-semibold shadow-lg ${
                  isConfirmDisabled ? "opacity-50 cursor-not-allowed" : ""
                }`}
              >
                Confirm
              </button>
              <button
                onClick={onCancel}
                aria-label="Cancel"
                className="px-8 py-3 rounded-full bg-gray-300 text-gray-800 hover:bg-gray-400 transition-colors font-semibold shadow-lg"
              >
                Cancel
              </button>
            </div>
          </div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

export default MintConfirmationModal;

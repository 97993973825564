import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import DashboardHeader from './components/DashboardHeader';
import StatsCard from './components/StatsCard';
import ChartSection from './components/ChartSection';
import StudentList from './components/StudentList';
import SchoolComparisonTable from './components/SchoolComparisonTable';
import AllTeams from 'views/admin/teams/components/AllTeams';
import InboxCard from './components/InboxCard';
import TimeLine from 'views/admin/main/applications/Calendar/components/TimeLine';
import { getUserProfile, fetchTeamsByMentor, fetchUsersAssignedToMentor, fetchApprovalRequests } from 'api';
import Loader from 'components/loaders/Loader';
import { useQuery } from '@tanstack/react-query';

const MentorHub = () => {
  const navigate = useNavigate();


  // Fetch user profile
  const {
    data: profileData,
    isLoading: profileLoading,
    error: profileError,
  } = useQuery({
    queryKey: ['userProfile'],
    queryFn: getUserProfile,
    refetchOnWindowFocus: true,
  });

  const user = profileData?.user;
  const mentorId = user?.role === 'mentor' ? user.id : user?.mentor?.id;

  // Fetch team by mentors
  const {
    data: teamsData,
    isLoading: teamsLoading,
    error: teamsError,
  } = useQuery({
    queryKey: ['teamsByMentor', mentorId],
    queryFn: () => fetchTeamsByMentor(mentorId),
    enabled: !!mentorId,
    refetchOnWindowFocus: true,
    refetchInterval: 60000,
  });

  // Fetch approval requests
  const {
    data: approvalsData,
    isLoading: approvalsLoading,
    error: approvalsError,
    refetch: refetchApprovals,
  } = useQuery({
    queryKey: ['approvals', mentorId],
    queryFn: () => fetchApprovalRequests(mentorId),
    enabled: !!mentorId,
    refetchOnWindowFocus: true,
    refetchOnMount: true,
  });

  // Log approvals data before filtering
console.log("🔍 Raw Approvals Data:", approvalsData);

    // Fetch users assigned to mentor
    const { data: usersData, isLoading: usersLoading, error: usersError } = useQuery({
      queryKey: ['usersAssignedToMentor', mentorId],
      queryFn: () => fetchUsersAssignedToMentor(mentorId),
      enabled: !!mentorId,
      refetchOnWindowFocus: true,
    });

  if (profileLoading || teamsLoading || approvalsLoading) {
    return <Loader />;
  }

  if (profileError || teamsError || approvalsError) {
    console.error('Error loading data:', profileError || teamsError || approvalsError);
    return <div>Error loading data.</div>
  }

  const teams = teamsData || [];
  const approvals = approvalsData || [];
  const users = usersData || [];

  // Filter out approved approvals
  const pendingApprovals = approvalsData ? approvalsData.filter(approval => approval.status !== "approved") : [];


  // Calculate the stats based on the teams
  const totalTeams = teams.length;
  const userCount = users.length;
  const projectsInProgress = teams.filter(team => team.teamSteps.some(step=> step.status === 'completed')).length
  const completedProjects = teams.filter(team => team.teamSteps.every(step => step.status === 'completed')).length;

  


  return (
    <div className="mt-3 grid h-full w-full gap-5 rounded-[20px]">
      

      {/* Stats Cards */}
      <div className="col-span-12 grid grid-cols-1 gap-5 md:grid-cols-2 xl:grid-cols-4">
        <StatsCard 
          title="Total Teams" 
          value={totalTeams} 
          icon="MdGroup" 
          bgColor="bg-lightPrimary dark:bg-navy-600" 
          iconColor="text-navy-700 dark:text-white"
          onClick={() => navigate('/admin/main/users/users-overview', { state: { activeTab: 'Teams' } })}
          isClickable={true}
        />
        <StatsCard 
          title="Student Roster" 
          value={userCount}
          icon="MdAccessTime" 
          bgColor="bg-lightPrimary dark:bg-navy-600" 
          iconColor="text-navy-700 dark:text-white"
          onClick={() => navigate('/admin/main/users/users-overview', { state: { activeTab: 'Users' } })}
          isClickable={true}
        />
        <StatsCard 
          title="Projects in Progress" 
          value={projectsInProgress}
          icon="MdAutorenew" 
          bgColor="bg-lightPrimary dark:bg-navy-600" 
          iconColor="text-navy-700 dark:text-white"
          isClickable={false}
        />

        <StatsCard 
          title="Leaderboard" 
          value="Rankings" 
          icon="MdBarChart" 
          bgColor="bg-lightPrimary dark:bg-navy-600" 
          iconColor="text-navy-700 dark:text-white"
          onClick={() => navigate('/admin/main/leaderboard')}
          isClickable={true}
        />
      </div>

      {/* Main Content */}
      <div className="col-span-12 grid grid-cols-1 gap-5 lg:grid-cols-3">
        {/* Left Column: ChartSection */}
        <div className="col-span-2 grid gap-5">
          <TimeLine teams= {teams}/>          
          <StudentList teamData={teams}/>
        </div>

        {/* Right Column: SchoolComparisonTable and InboxCard */}
        <div className="col-span-1 grid gap-5">
          <ChartSection mentorId={mentorId} team={teams} approvals={pendingApprovals} />
          <InboxCard />
        </div>
      </div>
    </div>
  );
};

export default MentorHub;

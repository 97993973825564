// TeamsHome.js
import React, { useEffect, useState} from 'react';
import Banner from './components/Banner';
import NftBanner3 from 'assets/img/nfts/NftBanner3.png';
import AvatarSimmple from 'assets/img/avatars/avatarSimmmple.png';
import Calendar from '../main/applications/calendar';
import Team from './Team/index';
import AllTeams from './components/AllTeams';
import GlassmorphicOverlay from 'components/modal/GlassmorphicOverlay';
import Loader from 'components/loaders/Loader';
import { checkSubscriptionEligibility, getUserProfile } from 'api';
import { useLocation } from 'react-router-dom';

const TeamsHome = () => {
  const [activeTab, setActiveTab] = useState(1);
  const [showOverlay, setShowOverlay] = useState(false);
  const [loading, setLoading] = useState(true);
  const location = useLocation();

  useEffect(() => {
    const checkEligibility = async () => {
      try {
        setLoading(true); // Ensure the loader is active during the check

        // Fetch user profile and get userId
        const userProfile = await getUserProfile();
        const userId = userProfile?.user?.id;

        if (!userId) {
          throw new Error('User ID is not available.');
        }

        // Call subscription eligibility API
        const eligibilityData = await checkSubscriptionEligibility([userId]);
        console.log('Subscription Eligibility Response:', eligibilityData);

        // Find the eligibility of the user from the response
        const userEligibility = eligibilityData.find((data) => data.userId === userId);

        if (userEligibility?.isEligible === false) {
          setShowOverlay(true);
        } else if (userEligibility?.isEligible === true) {
          setShowOverlay(false);
        } else {
          console.error('Unexpected response format for eligibility check:', eligibilityData);
        }
      } catch (error) {
        console.error('Error checking subscription eligibility:', error);
      } finally {
        setLoading(false); // Ensure loader stops
      }
    };

    checkEligibility();
  }, []);

  const renderContent = () => {
    switch (activeTab) {
      case 1:
        return <Team />;
      case 2:
        return <AllTeams />;
      case 3:
        return <Calendar />;
      default:
        return <Team />;
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const requestedTab = params.get('tab');
    if (requestedTab) {
      setActiveTab(Number(requestedTab));
    }
  }, [location]);


  return (
    // Add 'relative' to position the modal absolutely within this container
    <div className="relative mt-3 flex h-full w-full flex-col gap-5 rounded-2xl xl:flex-row">
      {loading ? (
        <Loader /> // Show loading while checking eligibility
      ) : (
        <>
          {showOverlay && (
            <GlassmorphicOverlay
              message="To access the Teams page and collaborate with others, please subscribe to our course."
            />
          )}

          <div className={`h-full w-full rounded-2xl ${showOverlay ? 'opacity-50 pointer-events-none' : 'opacity-100'}`}>
            <div className="relative group">
              <Banner
                image={NftBanner3}
                profile={AvatarSimmple}
                wallet="MyWallet420"
                address="simmple.web"
                name="Simmple"
                dat="Joined 17 Nov 2019"
                activeTab={activeTab}
                onTabChange={setActiveTab}
              />
              <div className="absolute top-0 right-0 p-4">
                <div className="hidden lg:flex space-x-4">
                  <button
                    className={`px-4 py-2 rounded-lg ${
                      activeTab === 1 ? 'bg-brand-500 text-white' : 'bg-gray-100 dark:bg-navy-800 text-gray-600 dark:text-white'
                    }`}
                    onClick={() => setActiveTab(1)}
                  >
                    My Team
                  </button>
                  <button
                    className={`px-4 py-2 rounded-lg ${
                      activeTab === 3 ? 'bg-brand-500 text-white' : 'bg-gray-100 dark:bg-navy-800 text-gray-600 dark:text-white'
                    }`}
                    onClick={() => setActiveTab(3)}
                  >
                    Calendar
                  </button>
                </div>
              </div>
            </div>

            {/* Content Section */}
            <div className="mt-5 w-full grid grid-cols-1 gap-5">{renderContent()}</div>
          </div>
        </>
      )}
    </div>
  );
};

export default TeamsHome;
